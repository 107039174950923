/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Metric
 */
export interface Metric {
    /**
     * 
     * @type {string}
     * @memberof Metric
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Metric
     */
    canonicalName?: string;
    /**
     * 
     * @type {{ [key: string]: string | undefined; }}
     * @memberof Metric
     */
    params?: { [key: string]: string | undefined; };
    /**
     * 
     * @type {{ [key: string]: number | undefined; }}
     * @memberof Metric
     */
    value?: { [key: string]: number | undefined; };
}

export function MetricFromJSON(json: any): Metric {
    return MetricFromJSONTyped(json, false);
}

export function MetricFromJSONTyped(json: any, ignoreDiscriminator: boolean): Metric {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'canonicalName': !exists(json, 'canonicalName') ? undefined : json['canonicalName'],
        'params': !exists(json, 'params') ? undefined : json['params'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function MetricToJSON(value?: Metric | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'canonicalName': value.canonicalName,
        'params': value.params,
        'value': value.value,
    };
}

