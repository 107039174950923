/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConnectorPluginConfigDefinition,
    ConnectorPluginConfigDefinitionFromJSON,
    ConnectorPluginConfigDefinitionFromJSONTyped,
    ConnectorPluginConfigDefinitionToJSON,
    ConnectorPluginConfigValue,
    ConnectorPluginConfigValueFromJSON,
    ConnectorPluginConfigValueFromJSONTyped,
    ConnectorPluginConfigValueToJSON,
} from './';

/**
 * 
 * @export
 * @interface ConnectorPluginConfig
 */
export interface ConnectorPluginConfig {
    /**
     * 
     * @type {ConnectorPluginConfigDefinition}
     * @memberof ConnectorPluginConfig
     */
    definition?: ConnectorPluginConfigDefinition;
    /**
     * 
     * @type {ConnectorPluginConfigValue}
     * @memberof ConnectorPluginConfig
     */
    value?: ConnectorPluginConfigValue;
}

export function ConnectorPluginConfigFromJSON(json: any): ConnectorPluginConfig {
    return ConnectorPluginConfigFromJSONTyped(json, false);
}

export function ConnectorPluginConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectorPluginConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'definition': !exists(json, 'definition') ? undefined : ConnectorPluginConfigDefinitionFromJSON(json['definition']),
        'value': !exists(json, 'value') ? undefined : ConnectorPluginConfigValueFromJSON(json['value']),
    };
}

export function ConnectorPluginConfigToJSON(value?: ConnectorPluginConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'definition': ConnectorPluginConfigDefinitionToJSON(value.definition),
        'value': ConnectorPluginConfigValueToJSON(value.value),
    };
}

