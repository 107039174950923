/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConnectorStatus,
    ConnectorStatusFromJSON,
    ConnectorStatusFromJSONTyped,
    ConnectorStatusToJSON,
    ConnectorType,
    ConnectorTypeFromJSON,
    ConnectorTypeFromJSONTyped,
    ConnectorTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface FullConnectorInfo
 */
export interface FullConnectorInfo {
    /**
     * 
     * @type {string}
     * @memberof FullConnectorInfo
     */
    connect: string;
    /**
     * 
     * @type {string}
     * @memberof FullConnectorInfo
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FullConnectorInfo
     */
    connectorClass?: string;
    /**
     * 
     * @type {ConnectorType}
     * @memberof FullConnectorInfo
     */
    type?: ConnectorType;
    /**
     * 
     * @type {Array<string>}
     * @memberof FullConnectorInfo
     */
    topics?: Array<string>;
    /**
     * 
     * @type {ConnectorStatus}
     * @memberof FullConnectorInfo
     */
    status: ConnectorStatus;
    /**
     * 
     * @type {number}
     * @memberof FullConnectorInfo
     */
    tasksCount?: number;
    /**
     * 
     * @type {number}
     * @memberof FullConnectorInfo
     */
    failedTasksCount?: number;
}

export function FullConnectorInfoFromJSON(json: any): FullConnectorInfo {
    return FullConnectorInfoFromJSONTyped(json, false);
}

export function FullConnectorInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FullConnectorInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'connect': json['connect'],
        'name': json['name'],
        'connectorClass': !exists(json, 'connector_class') ? undefined : json['connector_class'],
        'type': !exists(json, 'type') ? undefined : ConnectorTypeFromJSON(json['type']),
        'topics': !exists(json, 'topics') ? undefined : json['topics'],
        'status': ConnectorStatusFromJSON(json['status']),
        'tasksCount': !exists(json, 'tasks_count') ? undefined : json['tasks_count'],
        'failedTasksCount': !exists(json, 'failed_tasks_count') ? undefined : json['failed_tasks_count'],
    };
}

export function FullConnectorInfoToJSON(value?: FullConnectorInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'connect': value.connect,
        'name': value.name,
        'connector_class': value.connectorClass,
        'type': ConnectorTypeToJSON(value.type),
        'topics': value.topics,
        'status': ConnectorStatusToJSON(value.status),
        'tasks_count': value.tasksCount,
        'failed_tasks_count': value.failedTasksCount,
    };
}

