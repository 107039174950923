/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConsumerGroupOffsetsResetType,
    ConsumerGroupOffsetsResetTypeFromJSON,
    ConsumerGroupOffsetsResetTypeFromJSONTyped,
    ConsumerGroupOffsetsResetTypeToJSON,
    PartitionOffset,
    PartitionOffsetFromJSON,
    PartitionOffsetFromJSONTyped,
    PartitionOffsetToJSON,
} from './';

/**
 * 
 * @export
 * @interface ConsumerGroupOffsetsReset
 */
export interface ConsumerGroupOffsetsReset {
    /**
     * 
     * @type {string}
     * @memberof ConsumerGroupOffsetsReset
     */
    topic: string;
    /**
     * 
     * @type {ConsumerGroupOffsetsResetType}
     * @memberof ConsumerGroupOffsetsReset
     */
    resetType: ConsumerGroupOffsetsResetType;
    /**
     * list of target partitions, all partitions will be used if it is not set or empty
     * @type {Array<number>}
     * @memberof ConsumerGroupOffsetsReset
     */
    partitions?: Array<number>;
    /**
     * should be set if resetType is TIMESTAMP
     * @type {number}
     * @memberof ConsumerGroupOffsetsReset
     */
    resetToTimestamp?: number;
    /**
     * List of partition offsets to reset to, should be set when resetType is OFFSET
     * @type {Array<PartitionOffset>}
     * @memberof ConsumerGroupOffsetsReset
     */
    partitionsOffsets?: Array<PartitionOffset>;
}

export function ConsumerGroupOffsetsResetFromJSON(json: any): ConsumerGroupOffsetsReset {
    return ConsumerGroupOffsetsResetFromJSONTyped(json, false);
}

export function ConsumerGroupOffsetsResetFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsumerGroupOffsetsReset {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'topic': json['topic'],
        'resetType': ConsumerGroupOffsetsResetTypeFromJSON(json['resetType']),
        'partitions': !exists(json, 'partitions') ? undefined : json['partitions'],
        'resetToTimestamp': !exists(json, 'resetToTimestamp') ? undefined : json['resetToTimestamp'],
        'partitionsOffsets': !exists(json, 'partitionsOffsets') ? undefined : ((json['partitionsOffsets'] as Array<any>).map(PartitionOffsetFromJSON)),
    };
}

export function ConsumerGroupOffsetsResetToJSON(value?: ConsumerGroupOffsetsReset | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'topic': value.topic,
        'resetType': ConsumerGroupOffsetsResetTypeToJSON(value.resetType),
        'partitions': value.partitions,
        'resetToTimestamp': value.resetToTimestamp,
        'partitionsOffsets': value.partitionsOffsets === undefined ? undefined : ((value.partitionsOffsets as Array<any>).map(PartitionOffsetToJSON)),
    };
}

