/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompatibilityCheckResponse,
    CompatibilityCheckResponseFromJSON,
    CompatibilityCheckResponseToJSON,
    CompatibilityLevel,
    CompatibilityLevelFromJSON,
    CompatibilityLevelToJSON,
    NewSchemaSubject,
    NewSchemaSubjectFromJSON,
    NewSchemaSubjectToJSON,
    SchemaSubject,
    SchemaSubjectFromJSON,
    SchemaSubjectToJSON,
    SchemaSubjectsResponse,
    SchemaSubjectsResponseFromJSON,
    SchemaSubjectsResponseToJSON,
} from '../models';

export interface CheckSchemaCompatibilityRequest {
    clusterName: string;
    subject: string;
    newSchemaSubject?: NewSchemaSubject;
}

export interface CreateNewSchemaRequest {
    clusterName: string;
    newSchemaSubject?: NewSchemaSubject;
}

export interface DeleteLatestSchemaRequest {
    clusterName: string;
    subject: string;
}

export interface DeleteSchemaRequest {
    clusterName: string;
    subject: string;
}

export interface DeleteSchemaByVersionRequest {
    clusterName: string;
    subject: string;
    version: number;
}

export interface GetAllVersionsBySubjectRequest {
    clusterName: string;
    subject: string;
}

export interface GetGlobalSchemaCompatibilityLevelRequest {
    clusterName: string;
}

export interface GetLatestSchemaRequest {
    clusterName: string;
    subject: string;
}

export interface GetSchemaByVersionRequest {
    clusterName: string;
    subject: string;
    version: number;
}

export interface GetSchemasRequest {
    clusterName: string;
    page?: number;
    perPage?: number;
    search?: string;
}

export interface UpdateGlobalSchemaCompatibilityLevelRequest {
    clusterName: string;
    compatibilityLevel?: CompatibilityLevel;
}

export interface UpdateSchemaCompatibilityLevelRequest {
    clusterName: string;
    subject: string;
    compatibilityLevel?: CompatibilityLevel;
}

/**
 * SchemasApi - interface
 * 
 * @export
 * @interface SchemasApiInterface
 */
export interface SchemasApiInterface {
    /**
     * 
     * @summary Check compatibility of the schema.
     * @param {string} clusterName 
     * @param {string} subject 
     * @param {NewSchemaSubject} [newSchemaSubject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchemasApiInterface
     */
    checkSchemaCompatibilityRaw(requestParameters: CheckSchemaCompatibilityRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompatibilityCheckResponse>>;

    /**
     * Check compatibility of the schema.
     */
    checkSchemaCompatibility(requestParameters: CheckSchemaCompatibilityRequest, initOverrides?: RequestInit): Promise<CompatibilityCheckResponse>;

    /**
     * 
     * @summary create a new subject schema
     * @param {string} clusterName 
     * @param {NewSchemaSubject} [newSchemaSubject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchemasApiInterface
     */
    createNewSchemaRaw(requestParameters: CreateNewSchemaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchemaSubject>>;

    /**
     * create a new subject schema
     */
    createNewSchema(requestParameters: CreateNewSchemaRequest, initOverrides?: RequestInit): Promise<SchemaSubject>;

    /**
     * 
     * @summary delete the latest schema from schema registry
     * @param {string} clusterName 
     * @param {string} subject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchemasApiInterface
     */
    deleteLatestSchemaRaw(requestParameters: DeleteLatestSchemaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * delete the latest schema from schema registry
     */
    deleteLatestSchema(requestParameters: DeleteLatestSchemaRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary delete schema from Schema Registry service
     * @param {string} clusterName 
     * @param {string} subject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchemasApiInterface
     */
    deleteSchemaRaw(requestParameters: DeleteSchemaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * delete schema from Schema Registry service
     */
    deleteSchema(requestParameters: DeleteSchemaRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary delete schema by version from schema registry
     * @param {string} clusterName 
     * @param {string} subject 
     * @param {number} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchemasApiInterface
     */
    deleteSchemaByVersionRaw(requestParameters: DeleteSchemaByVersionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * delete schema by version from schema registry
     */
    deleteSchemaByVersion(requestParameters: DeleteSchemaByVersionRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary get all version of subject from Schema Registry service
     * @param {string} clusterName 
     * @param {string} subject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchemasApiInterface
     */
    getAllVersionsBySubjectRaw(requestParameters: GetAllVersionsBySubjectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SchemaSubject>>>;

    /**
     * get all version of subject from Schema Registry service
     */
    getAllVersionsBySubject(requestParameters: GetAllVersionsBySubjectRequest, initOverrides?: RequestInit): Promise<Array<SchemaSubject>>;

    /**
     * 
     * @summary Get global schema compatibility level
     * @param {string} clusterName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchemasApiInterface
     */
    getGlobalSchemaCompatibilityLevelRaw(requestParameters: GetGlobalSchemaCompatibilityLevelRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompatibilityLevel>>;

    /**
     * Get global schema compatibility level
     */
    getGlobalSchemaCompatibilityLevel(requestParameters: GetGlobalSchemaCompatibilityLevelRequest, initOverrides?: RequestInit): Promise<CompatibilityLevel>;

    /**
     * 
     * @summary get the latest schema from Schema Registry service
     * @param {string} clusterName 
     * @param {string} subject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchemasApiInterface
     */
    getLatestSchemaRaw(requestParameters: GetLatestSchemaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchemaSubject>>;

    /**
     * get the latest schema from Schema Registry service
     */
    getLatestSchema(requestParameters: GetLatestSchemaRequest, initOverrides?: RequestInit): Promise<SchemaSubject>;

    /**
     * 
     * @summary get schema by version from Schema Registry service
     * @param {string} clusterName 
     * @param {string} subject 
     * @param {number} version 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchemasApiInterface
     */
    getSchemaByVersionRaw(requestParameters: GetSchemaByVersionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchemaSubject>>;

    /**
     * get schema by version from Schema Registry service
     */
    getSchemaByVersion(requestParameters: GetSchemaByVersionRequest, initOverrides?: RequestInit): Promise<SchemaSubject>;

    /**
     * 
     * @summary get all schemas of latest version from Schema Registry service
     * @param {string} clusterName 
     * @param {number} [page] 
     * @param {number} [perPage] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchemasApiInterface
     */
    getSchemasRaw(requestParameters: GetSchemasRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchemaSubjectsResponse>>;

    /**
     * get all schemas of latest version from Schema Registry service
     */
    getSchemas(requestParameters: GetSchemasRequest, initOverrides?: RequestInit): Promise<SchemaSubjectsResponse>;

    /**
     * 
     * @summary Update compatibility level globally
     * @param {string} clusterName 
     * @param {CompatibilityLevel} [compatibilityLevel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchemasApiInterface
     */
    updateGlobalSchemaCompatibilityLevelRaw(requestParameters: UpdateGlobalSchemaCompatibilityLevelRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * Update compatibility level globally
     */
    updateGlobalSchemaCompatibilityLevel(requestParameters: UpdateGlobalSchemaCompatibilityLevelRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary Update compatibility level for specific schema.
     * @param {string} clusterName 
     * @param {string} subject 
     * @param {CompatibilityLevel} [compatibilityLevel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchemasApiInterface
     */
    updateSchemaCompatibilityLevelRaw(requestParameters: UpdateSchemaCompatibilityLevelRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * Update compatibility level for specific schema.
     */
    updateSchemaCompatibilityLevel(requestParameters: UpdateSchemaCompatibilityLevelRequest, initOverrides?: RequestInit): Promise<void>;

}

/**
 * 
 */
export class SchemasApi extends runtime.BaseAPI implements SchemasApiInterface {

    /**
     * Check compatibility of the schema.
     */
    async checkSchemaCompatibilityRaw(requestParameters: CheckSchemaCompatibilityRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompatibilityCheckResponse>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling checkSchemaCompatibility.');
        }

        if (requestParameters.subject === null || requestParameters.subject === undefined) {
            throw new runtime.RequiredError('subject','Required parameter requestParameters.subject was null or undefined when calling checkSchemaCompatibility.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/clusters/{clusterName}/schemas/{subject}/check`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"subject"}}`, encodeURIComponent(String(requestParameters.subject))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewSchemaSubjectToJSON(requestParameters.newSchemaSubject),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompatibilityCheckResponseFromJSON(jsonValue));
    }

    /**
     * Check compatibility of the schema.
     */
    async checkSchemaCompatibility(requestParameters: CheckSchemaCompatibilityRequest, initOverrides?: RequestInit): Promise<CompatibilityCheckResponse> {
        const response = await this.checkSchemaCompatibilityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * create a new subject schema
     */
    async createNewSchemaRaw(requestParameters: CreateNewSchemaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchemaSubject>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling createNewSchema.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/clusters/{clusterName}/schemas`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewSchemaSubjectToJSON(requestParameters.newSchemaSubject),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchemaSubjectFromJSON(jsonValue));
    }

    /**
     * create a new subject schema
     */
    async createNewSchema(requestParameters: CreateNewSchemaRequest, initOverrides?: RequestInit): Promise<SchemaSubject> {
        const response = await this.createNewSchemaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * delete the latest schema from schema registry
     */
    async deleteLatestSchemaRaw(requestParameters: DeleteLatestSchemaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling deleteLatestSchema.');
        }

        if (requestParameters.subject === null || requestParameters.subject === undefined) {
            throw new runtime.RequiredError('subject','Required parameter requestParameters.subject was null or undefined when calling deleteLatestSchema.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/schemas/{subject}/latest`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"subject"}}`, encodeURIComponent(String(requestParameters.subject))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete the latest schema from schema registry
     */
    async deleteLatestSchema(requestParameters: DeleteLatestSchemaRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteLatestSchemaRaw(requestParameters, initOverrides);
    }

    /**
     * delete schema from Schema Registry service
     */
    async deleteSchemaRaw(requestParameters: DeleteSchemaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling deleteSchema.');
        }

        if (requestParameters.subject === null || requestParameters.subject === undefined) {
            throw new runtime.RequiredError('subject','Required parameter requestParameters.subject was null or undefined when calling deleteSchema.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/schemas/{subject}`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"subject"}}`, encodeURIComponent(String(requestParameters.subject))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete schema from Schema Registry service
     */
    async deleteSchema(requestParameters: DeleteSchemaRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteSchemaRaw(requestParameters, initOverrides);
    }

    /**
     * delete schema by version from schema registry
     */
    async deleteSchemaByVersionRaw(requestParameters: DeleteSchemaByVersionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling deleteSchemaByVersion.');
        }

        if (requestParameters.subject === null || requestParameters.subject === undefined) {
            throw new runtime.RequiredError('subject','Required parameter requestParameters.subject was null or undefined when calling deleteSchemaByVersion.');
        }

        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version','Required parameter requestParameters.version was null or undefined when calling deleteSchemaByVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/schemas/{subject}/versions/{version}`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"subject"}}`, encodeURIComponent(String(requestParameters.subject))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete schema by version from schema registry
     */
    async deleteSchemaByVersion(requestParameters: DeleteSchemaByVersionRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteSchemaByVersionRaw(requestParameters, initOverrides);
    }

    /**
     * get all version of subject from Schema Registry service
     */
    async getAllVersionsBySubjectRaw(requestParameters: GetAllVersionsBySubjectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SchemaSubject>>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling getAllVersionsBySubject.');
        }

        if (requestParameters.subject === null || requestParameters.subject === undefined) {
            throw new runtime.RequiredError('subject','Required parameter requestParameters.subject was null or undefined when calling getAllVersionsBySubject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/schemas/{subject}/versions`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"subject"}}`, encodeURIComponent(String(requestParameters.subject))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SchemaSubjectFromJSON));
    }

    /**
     * get all version of subject from Schema Registry service
     */
    async getAllVersionsBySubject(requestParameters: GetAllVersionsBySubjectRequest, initOverrides?: RequestInit): Promise<Array<SchemaSubject>> {
        const response = await this.getAllVersionsBySubjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get global schema compatibility level
     */
    async getGlobalSchemaCompatibilityLevelRaw(requestParameters: GetGlobalSchemaCompatibilityLevelRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompatibilityLevel>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling getGlobalSchemaCompatibilityLevel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/schemas/compatibility`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompatibilityLevelFromJSON(jsonValue));
    }

    /**
     * Get global schema compatibility level
     */
    async getGlobalSchemaCompatibilityLevel(requestParameters: GetGlobalSchemaCompatibilityLevelRequest, initOverrides?: RequestInit): Promise<CompatibilityLevel> {
        const response = await this.getGlobalSchemaCompatibilityLevelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get the latest schema from Schema Registry service
     */
    async getLatestSchemaRaw(requestParameters: GetLatestSchemaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchemaSubject>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling getLatestSchema.');
        }

        if (requestParameters.subject === null || requestParameters.subject === undefined) {
            throw new runtime.RequiredError('subject','Required parameter requestParameters.subject was null or undefined when calling getLatestSchema.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/schemas/{subject}/latest`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"subject"}}`, encodeURIComponent(String(requestParameters.subject))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchemaSubjectFromJSON(jsonValue));
    }

    /**
     * get the latest schema from Schema Registry service
     */
    async getLatestSchema(requestParameters: GetLatestSchemaRequest, initOverrides?: RequestInit): Promise<SchemaSubject> {
        const response = await this.getLatestSchemaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get schema by version from Schema Registry service
     */
    async getSchemaByVersionRaw(requestParameters: GetSchemaByVersionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchemaSubject>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling getSchemaByVersion.');
        }

        if (requestParameters.subject === null || requestParameters.subject === undefined) {
            throw new runtime.RequiredError('subject','Required parameter requestParameters.subject was null or undefined when calling getSchemaByVersion.');
        }

        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version','Required parameter requestParameters.version was null or undefined when calling getSchemaByVersion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/schemas/{subject}/versions/{version}`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"subject"}}`, encodeURIComponent(String(requestParameters.subject))).replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchemaSubjectFromJSON(jsonValue));
    }

    /**
     * get schema by version from Schema Registry service
     */
    async getSchemaByVersion(requestParameters: GetSchemaByVersionRequest, initOverrides?: RequestInit): Promise<SchemaSubject> {
        const response = await this.getSchemaByVersionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get all schemas of latest version from Schema Registry service
     */
    async getSchemasRaw(requestParameters: GetSchemasRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SchemaSubjectsResponse>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling getSchemas.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/schemas`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SchemaSubjectsResponseFromJSON(jsonValue));
    }

    /**
     * get all schemas of latest version from Schema Registry service
     */
    async getSchemas(requestParameters: GetSchemasRequest, initOverrides?: RequestInit): Promise<SchemaSubjectsResponse> {
        const response = await this.getSchemasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update compatibility level globally
     */
    async updateGlobalSchemaCompatibilityLevelRaw(requestParameters: UpdateGlobalSchemaCompatibilityLevelRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling updateGlobalSchemaCompatibilityLevel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/clusters/{clusterName}/schemas/compatibility`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompatibilityLevelToJSON(requestParameters.compatibilityLevel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update compatibility level globally
     */
    async updateGlobalSchemaCompatibilityLevel(requestParameters: UpdateGlobalSchemaCompatibilityLevelRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateGlobalSchemaCompatibilityLevelRaw(requestParameters, initOverrides);
    }

    /**
     * Update compatibility level for specific schema.
     */
    async updateSchemaCompatibilityLevelRaw(requestParameters: UpdateSchemaCompatibilityLevelRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling updateSchemaCompatibilityLevel.');
        }

        if (requestParameters.subject === null || requestParameters.subject === undefined) {
            throw new runtime.RequiredError('subject','Required parameter requestParameters.subject was null or undefined when calling updateSchemaCompatibilityLevel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/clusters/{clusterName}/schemas/{subject}/compatibility`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"subject"}}`, encodeURIComponent(String(requestParameters.subject))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CompatibilityLevelToJSON(requestParameters.compatibilityLevel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update compatibility level for specific schema.
     */
    async updateSchemaCompatibilityLevel(requestParameters: UpdateSchemaCompatibilityLevelRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateSchemaCompatibilityLevelRaw(requestParameters, initOverrides);
    }

}
