/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Table
 */
export interface Table {
    /**
     * 
     * @type {Array<string>}
     * @memberof Table
     */
    headers: Array<string>;
    /**
     * 
     * @type {Array<Array<string>>}
     * @memberof Table
     */
    rows: Array<Array<string>>;
}

export function TableFromJSON(json: any): Table {
    return TableFromJSONTyped(json, false);
}

export function TableFromJSONTyped(json: any, ignoreDiscriminator: boolean): Table {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'headers': json['headers'],
        'rows': json['rows'],
    };
}

export function TableToJSON(value?: Table | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'headers': value.headers,
        'rows': value.rows,
    };
}

