/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KsqlCommandV2
 */
export interface KsqlCommandV2 {
    /**
     * 
     * @type {string}
     * @memberof KsqlCommandV2
     */
    ksql: string;
    /**
     * 
     * @type {{ [key: string]: string | undefined; }}
     * @memberof KsqlCommandV2
     */
    streamsProperties?: { [key: string]: string | undefined; };
}

export function KsqlCommandV2FromJSON(json: any): KsqlCommandV2 {
    return KsqlCommandV2FromJSONTyped(json, false);
}

export function KsqlCommandV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): KsqlCommandV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ksql': json['ksql'],
        'streamsProperties': !exists(json, 'streamsProperties') ? undefined : json['streamsProperties'],
    };
}

export function KsqlCommandV2ToJSON(value?: KsqlCommandV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ksql': value.ksql,
        'streamsProperties': value.streamsProperties,
    };
}

