/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ConnectorAction {
    RESTART = 'RESTART',
    RESTART_ALL_TASKS = 'RESTART_ALL_TASKS',
    RESTART_FAILED_TASKS = 'RESTART_FAILED_TASKS',
    PAUSE = 'PAUSE',
    RESUME = 'RESUME'
}

export function ConnectorActionFromJSON(json: any): ConnectorAction {
    return ConnectorActionFromJSONTyped(json, false);
}

export function ConnectorActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectorAction {
    return json as ConnectorAction;
}

export function ConnectorActionToJSON(value?: ConnectorAction | null): any {
    return value as any;
}

