/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SchemaType,
    SchemaTypeFromJSON,
    SchemaTypeFromJSONTyped,
    SchemaTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface SchemaSubject
 */
export interface SchemaSubject {
    /**
     * 
     * @type {string}
     * @memberof SchemaSubject
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof SchemaSubject
     */
    version: string;
    /**
     * 
     * @type {number}
     * @memberof SchemaSubject
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SchemaSubject
     */
    schema: string;
    /**
     * 
     * @type {string}
     * @memberof SchemaSubject
     */
    compatibilityLevel: string;
    /**
     * 
     * @type {SchemaType}
     * @memberof SchemaSubject
     */
    schemaType: SchemaType;
}

export function SchemaSubjectFromJSON(json: any): SchemaSubject {
    return SchemaSubjectFromJSONTyped(json, false);
}

export function SchemaSubjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchemaSubject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subject': json['subject'],
        'version': json['version'],
        'id': json['id'],
        'schema': json['schema'],
        'compatibilityLevel': json['compatibilityLevel'],
        'schemaType': SchemaTypeFromJSON(json['schemaType']),
    };
}

export function SchemaSubjectToJSON(value?: SchemaSubject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subject': value.subject,
        'version': value.version,
        'id': value.id,
        'schema': value.schema,
        'compatibilityLevel': value.compatibilityLevel,
        'schemaType': SchemaTypeToJSON(value.schemaType),
    };
}

