/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KsqlTableResponse,
    KsqlTableResponseFromJSON,
    KsqlTableResponseFromJSONTyped,
    KsqlTableResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface KsqlResponse
 */
export interface KsqlResponse {
    /**
     * 
     * @type {KsqlTableResponse}
     * @memberof KsqlResponse
     */
    table?: KsqlTableResponse;
}

export function KsqlResponseFromJSON(json: any): KsqlResponse {
    return KsqlResponseFromJSONTyped(json, false);
}

export function KsqlResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KsqlResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'table': !exists(json, 'table') ? undefined : KsqlTableResponseFromJSON(json['table']),
    };
}

export function KsqlResponseToJSON(value?: KsqlResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'table': KsqlTableResponseToJSON(value.table),
    };
}

