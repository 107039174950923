/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConsumerGroup,
    ConsumerGroupFromJSON,
    ConsumerGroupFromJSONTyped,
    ConsumerGroupToJSON,
} from './';

/**
 * 
 * @export
 * @interface ConsumerGroupsPageResponse
 */
export interface ConsumerGroupsPageResponse {
    /**
     * 
     * @type {number}
     * @memberof ConsumerGroupsPageResponse
     */
    pageCount?: number;
    /**
     * 
     * @type {Array<ConsumerGroup>}
     * @memberof ConsumerGroupsPageResponse
     */
    consumerGroups?: Array<ConsumerGroup>;
}

export function ConsumerGroupsPageResponseFromJSON(json: any): ConsumerGroupsPageResponse {
    return ConsumerGroupsPageResponseFromJSONTyped(json, false);
}

export function ConsumerGroupsPageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsumerGroupsPageResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageCount': !exists(json, 'pageCount') ? undefined : json['pageCount'],
        'consumerGroups': !exists(json, 'consumerGroups') ? undefined : ((json['consumerGroups'] as Array<any>).map(ConsumerGroupFromJSON)),
    };
}

export function ConsumerGroupsPageResponseToJSON(value?: ConsumerGroupsPageResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageCount': value.pageCount,
        'consumerGroups': value.consumerGroups === undefined ? undefined : ((value.consumerGroups as Array<any>).map(ConsumerGroupToJSON)),
    };
}

