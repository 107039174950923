/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TopicUpdate
 */
export interface TopicUpdate {
    /**
     * 
     * @type {{ [key: string]: string | undefined; }}
     * @memberof TopicUpdate
     */
    configs: { [key: string]: string | undefined; };
}

export function TopicUpdateFromJSON(json: any): TopicUpdate {
    return TopicUpdateFromJSONTyped(json, false);
}

export function TopicUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopicUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'configs': json['configs'],
    };
}

export function TopicUpdateToJSON(value?: TopicUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'configs': value.configs,
    };
}

