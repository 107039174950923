/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Topic,
    TopicFromJSON,
    TopicFromJSONTyped,
    TopicToJSON,
} from './';

/**
 * 
 * @export
 * @interface TopicsResponse
 */
export interface TopicsResponse {
    /**
     * 
     * @type {number}
     * @memberof TopicsResponse
     */
    pageCount?: number;
    /**
     * 
     * @type {Array<Topic>}
     * @memberof TopicsResponse
     */
    topics?: Array<Topic>;
}

export function TopicsResponseFromJSON(json: any): TopicsResponse {
    return TopicsResponseFromJSONTyped(json, false);
}

export function TopicsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopicsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageCount': !exists(json, 'pageCount') ? undefined : json['pageCount'],
        'topics': !exists(json, 'topics') ? undefined : ((json['topics'] as Array<any>).map(TopicFromJSON)),
    };
}

export function TopicsResponseToJSON(value?: TopicsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageCount': value.pageCount,
        'topics': value.topics === undefined ? undefined : ((value.topics as Array<any>).map(TopicToJSON)),
    };
}

