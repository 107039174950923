/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SeekType {
    BEGINNING = 'BEGINNING',
    OFFSET = 'OFFSET',
    TIMESTAMP = 'TIMESTAMP',
    LATEST = 'LATEST'
}

export function SeekTypeFromJSON(json: any): SeekType {
    return SeekTypeFromJSONTyped(json, false);
}

export function SeekTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeekType {
    return json as SeekType;
}

export function SeekTypeToJSON(value?: SeekType | null): any {
    return value as any;
}

