/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConnectorPluginConfigDefinition
 */
export interface ConnectorPluginConfigDefinition {
    /**
     * 
     * @type {string}
     * @memberof ConnectorPluginConfigDefinition
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectorPluginConfigDefinition
     */
    type?: ConnectorPluginConfigDefinitionTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectorPluginConfigDefinition
     */
    required?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConnectorPluginConfigDefinition
     */
    defaultValue?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectorPluginConfigDefinition
     */
    importance?: ConnectorPluginConfigDefinitionImportanceEnum;
    /**
     * 
     * @type {string}
     * @memberof ConnectorPluginConfigDefinition
     */
    documentation?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectorPluginConfigDefinition
     */
    group?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectorPluginConfigDefinition
     */
    width?: ConnectorPluginConfigDefinitionWidthEnum;
    /**
     * 
     * @type {string}
     * @memberof ConnectorPluginConfigDefinition
     */
    displayName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConnectorPluginConfigDefinition
     */
    dependents?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ConnectorPluginConfigDefinition
     */
    order?: number;
}

/**
* @export
* @enum {string}
*/
export enum ConnectorPluginConfigDefinitionTypeEnum {
    BOOLEAN = 'BOOLEAN',
    CLASS = 'CLASS',
    DOUBLE = 'DOUBLE',
    INT = 'INT',
    LIST = 'LIST',
    LONG = 'LONG',
    PASSWORD = 'PASSWORD',
    SHORT = 'SHORT',
    STRING = 'STRING'
}/**
* @export
* @enum {string}
*/
export enum ConnectorPluginConfigDefinitionImportanceEnum {
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH'
}/**
* @export
* @enum {string}
*/
export enum ConnectorPluginConfigDefinitionWidthEnum {
    SHORT = 'SHORT',
    MEDIUM = 'MEDIUM',
    LONG = 'LONG',
    NONE = 'NONE'
}

export function ConnectorPluginConfigDefinitionFromJSON(json: any): ConnectorPluginConfigDefinition {
    return ConnectorPluginConfigDefinitionFromJSONTyped(json, false);
}

export function ConnectorPluginConfigDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectorPluginConfigDefinition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'required': !exists(json, 'required') ? undefined : json['required'],
        'defaultValue': !exists(json, 'default_value') ? undefined : json['default_value'],
        'importance': !exists(json, 'importance') ? undefined : json['importance'],
        'documentation': !exists(json, 'documentation') ? undefined : json['documentation'],
        'group': !exists(json, 'group') ? undefined : json['group'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'displayName': !exists(json, 'display_name') ? undefined : json['display_name'],
        'dependents': !exists(json, 'dependents') ? undefined : json['dependents'],
        'order': !exists(json, 'order') ? undefined : json['order'],
    };
}

export function ConnectorPluginConfigDefinitionToJSON(value?: ConnectorPluginConfigDefinition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': value.type,
        'required': value.required,
        'default_value': value.defaultValue,
        'importance': value.importance,
        'documentation': value.documentation,
        'group': value.group,
        'width': value.width,
        'display_name': value.displayName,
        'dependents': value.dependents,
        'order': value.order,
    };
}

