/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ConnectorState {
    RUNNING = 'RUNNING',
    FAILED = 'FAILED',
    PAUSED = 'PAUSED',
    UNASSIGNED = 'UNASSIGNED',
    TASK_FAILED = 'TASK_FAILED'
}

export function ConnectorStateFromJSON(json: any): ConnectorState {
    return ConnectorStateFromJSONTyped(json, false);
}

export function ConnectorStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectorState {
    return json as ConnectorState;
}

export function ConnectorStateToJSON(value?: ConnectorState | null): any {
    return value as any;
}

