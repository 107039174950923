/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConnectorPluginConfigValue
 */
export interface ConnectorPluginConfigValue {
    /**
     * 
     * @type {string}
     * @memberof ConnectorPluginConfigValue
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectorPluginConfigValue
     */
    value?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConnectorPluginConfigValue
     */
    recommendedValues?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConnectorPluginConfigValue
     */
    errors?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectorPluginConfigValue
     */
    visible?: boolean;
}

export function ConnectorPluginConfigValueFromJSON(json: any): ConnectorPluginConfigValue {
    return ConnectorPluginConfigValueFromJSONTyped(json, false);
}

export function ConnectorPluginConfigValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectorPluginConfigValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'recommendedValues': !exists(json, 'recommended_values') ? undefined : json['recommended_values'],
        'errors': !exists(json, 'errors') ? undefined : json['errors'],
        'visible': !exists(json, 'visible') ? undefined : json['visible'],
    };
}

export function ConnectorPluginConfigValueToJSON(value?: ConnectorPluginConfigValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'value': value.value,
        'recommended_values': value.recommendedValues,
        'errors': value.errors,
        'visible': value.visible,
    };
}

