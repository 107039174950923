/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SeekDirection {
    FORWARD = 'FORWARD',
    BACKWARD = 'BACKWARD',
    TAILING = 'TAILING'
}

export function SeekDirectionFromJSON(json: any): SeekDirection {
    return SeekDirectionFromJSONTyped(json, false);
}

export function SeekDirectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeekDirection {
    return json as SeekDirection;
}

export function SeekDirectionToJSON(value?: SeekDirection | null): any {
    return value as any;
}

