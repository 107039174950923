/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SchemaSubject,
    SchemaSubjectFromJSON,
    SchemaSubjectFromJSONTyped,
    SchemaSubjectToJSON,
} from './';

/**
 * 
 * @export
 * @interface SchemaSubjectsResponse
 */
export interface SchemaSubjectsResponse {
    /**
     * 
     * @type {number}
     * @memberof SchemaSubjectsResponse
     */
    pageCount?: number;
    /**
     * 
     * @type {Array<SchemaSubject>}
     * @memberof SchemaSubjectsResponse
     */
    schemas?: Array<SchemaSubject>;
}

export function SchemaSubjectsResponseFromJSON(json: any): SchemaSubjectsResponse {
    return SchemaSubjectsResponseFromJSONTyped(json, false);
}

export function SchemaSubjectsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchemaSubjectsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageCount': !exists(json, 'pageCount') ? undefined : json['pageCount'],
        'schemas': !exists(json, 'schemas') ? undefined : ((json['schemas'] as Array<any>).map(SchemaSubjectFromJSON)),
    };
}

export function SchemaSubjectsResponseToJSON(value?: SchemaSubjectsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageCount': value.pageCount,
        'schemas': value.schemas === undefined ? undefined : ((value.schemas as Array<any>).map(SchemaSubjectToJSON)),
    };
}

