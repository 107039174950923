/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MessageSchema,
    MessageSchemaFromJSON,
    MessageSchemaFromJSONTyped,
    MessageSchemaToJSON,
} from './';

/**
 * 
 * @export
 * @interface TopicMessageSchema
 */
export interface TopicMessageSchema {
    /**
     * 
     * @type {MessageSchema}
     * @memberof TopicMessageSchema
     */
    key: MessageSchema;
    /**
     * 
     * @type {MessageSchema}
     * @memberof TopicMessageSchema
     */
    value: MessageSchema;
}

export function TopicMessageSchemaFromJSON(json: any): TopicMessageSchema {
    return TopicMessageSchemaFromJSONTyped(json, false);
}

export function TopicMessageSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopicMessageSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': MessageSchemaFromJSON(json['key']),
        'value': MessageSchemaFromJSON(json['value']),
    };
}

export function TopicMessageSchemaToJSON(value?: TopicMessageSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': MessageSchemaToJSON(value.key),
        'value': MessageSchemaToJSON(value.value),
    };
}

