/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MessageFormat,
    MessageFormatFromJSON,
    MessageFormatFromJSONTyped,
    MessageFormatToJSON,
} from './';

/**
 * 
 * @export
 * @interface TopicMessage
 */
export interface TopicMessage {
    /**
     * 
     * @type {number}
     * @memberof TopicMessage
     */
    partition: number;
    /**
     * 
     * @type {number}
     * @memberof TopicMessage
     */
    offset: number;
    /**
     * 
     * @type {Date}
     * @memberof TopicMessage
     */
    timestamp: Date;
    /**
     * 
     * @type {string}
     * @memberof TopicMessage
     */
    timestampType?: TopicMessageTimestampTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TopicMessage
     */
    key?: string;
    /**
     * 
     * @type {{ [key: string]: string | undefined; }}
     * @memberof TopicMessage
     */
    headers?: { [key: string]: string | undefined; };
    /**
     * 
     * @type {string}
     * @memberof TopicMessage
     */
    content?: string;
    /**
     * 
     * @type {MessageFormat}
     * @memberof TopicMessage
     */
    keyFormat?: MessageFormat;
    /**
     * 
     * @type {MessageFormat}
     * @memberof TopicMessage
     */
    valueFormat?: MessageFormat;
    /**
     * 
     * @type {number}
     * @memberof TopicMessage
     */
    keySize?: number;
    /**
     * 
     * @type {number}
     * @memberof TopicMessage
     */
    valueSize?: number;
    /**
     * 
     * @type {string}
     * @memberof TopicMessage
     */
    keySchemaId?: string;
    /**
     * 
     * @type {string}
     * @memberof TopicMessage
     */
    valueSchemaId?: string;
    /**
     * 
     * @type {number}
     * @memberof TopicMessage
     */
    headersSize?: number;
}

/**
* @export
* @enum {string}
*/
export enum TopicMessageTimestampTypeEnum {
    NO_TIMESTAMP_TYPE = 'NO_TIMESTAMP_TYPE',
    CREATE_TIME = 'CREATE_TIME',
    LOG_APPEND_TIME = 'LOG_APPEND_TIME'
}

export function TopicMessageFromJSON(json: any): TopicMessage {
    return TopicMessageFromJSONTyped(json, false);
}

export function TopicMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopicMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'partition': json['partition'],
        'offset': json['offset'],
        'timestamp': (new Date(json['timestamp'])),
        'timestampType': !exists(json, 'timestampType') ? undefined : json['timestampType'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'headers': !exists(json, 'headers') ? undefined : json['headers'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'keyFormat': !exists(json, 'keyFormat') ? undefined : MessageFormatFromJSON(json['keyFormat']),
        'valueFormat': !exists(json, 'valueFormat') ? undefined : MessageFormatFromJSON(json['valueFormat']),
        'keySize': !exists(json, 'keySize') ? undefined : json['keySize'],
        'valueSize': !exists(json, 'valueSize') ? undefined : json['valueSize'],
        'keySchemaId': !exists(json, 'keySchemaId') ? undefined : json['keySchemaId'],
        'valueSchemaId': !exists(json, 'valueSchemaId') ? undefined : json['valueSchemaId'],
        'headersSize': !exists(json, 'headersSize') ? undefined : json['headersSize'],
    };
}

export function TopicMessageToJSON(value?: TopicMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'partition': value.partition,
        'offset': value.offset,
        'timestamp': (value.timestamp.toISOString()),
        'timestampType': value.timestampType,
        'key': value.key,
        'headers': value.headers,
        'content': value.content,
        'keyFormat': MessageFormatToJSON(value.keyFormat),
        'valueFormat': MessageFormatToJSON(value.valueFormat),
        'keySize': value.keySize,
        'valueSize': value.valueSize,
        'keySchemaId': value.keySchemaId,
        'valueSchemaId': value.valueSchemaId,
        'headersSize': value.headersSize,
    };
}

