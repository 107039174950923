/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ConnectorTaskStatus {
    RUNNING = 'RUNNING',
    FAILED = 'FAILED',
    PAUSED = 'PAUSED',
    RESTARTING = 'RESTARTING',
    UNASSIGNED = 'UNASSIGNED'
}

export function ConnectorTaskStatusFromJSON(json: any): ConnectorTaskStatus {
    return ConnectorTaskStatusFromJSONTyped(json, false);
}

export function ConnectorTaskStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectorTaskStatus {
    return json as ConnectorTaskStatus;
}

export function ConnectorTaskStatusToJSON(value?: ConnectorTaskStatus | null): any {
    return value as any;
}

