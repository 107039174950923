/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaskId
 */
export interface TaskId {
    /**
     * 
     * @type {string}
     * @memberof TaskId
     */
    connector?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskId
     */
    task?: number;
}

export function TaskIdFromJSON(json: any): TaskId {
    return TaskIdFromJSONTyped(json, false);
}

export function TaskIdFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskId {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'connector': !exists(json, 'connector') ? undefined : json['connector'],
        'task': !exists(json, 'task') ? undefined : json['task'],
    };
}

export function TaskIdToJSON(value?: TaskId | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'connector': value.connector,
        'task': value.task,
    };
}

