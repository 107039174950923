/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CleanUpPolicy,
    CleanUpPolicyFromJSON,
    CleanUpPolicyFromJSONTyped,
    CleanUpPolicyToJSON,
    Partition,
    PartitionFromJSON,
    PartitionFromJSONTyped,
    PartitionToJSON,
} from './';

/**
 * 
 * @export
 * @interface TopicDetails
 */
export interface TopicDetails {
    /**
     * 
     * @type {string}
     * @memberof TopicDetails
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof TopicDetails
     */
    internal?: boolean;
    /**
     * 
     * @type {Array<Partition>}
     * @memberof TopicDetails
     */
    partitions?: Array<Partition>;
    /**
     * 
     * @type {number}
     * @memberof TopicDetails
     */
    partitionCount?: number;
    /**
     * 
     * @type {number}
     * @memberof TopicDetails
     */
    replicationFactor?: number;
    /**
     * 
     * @type {number}
     * @memberof TopicDetails
     */
    replicas?: number;
    /**
     * 
     * @type {number}
     * @memberof TopicDetails
     */
    inSyncReplicas?: number;
    /**
     * 
     * @type {number}
     * @memberof TopicDetails
     */
    bytesInPerSec?: number;
    /**
     * 
     * @type {number}
     * @memberof TopicDetails
     */
    bytesOutPerSec?: number;
    /**
     * 
     * @type {number}
     * @memberof TopicDetails
     */
    segmentSize?: number;
    /**
     * 
     * @type {number}
     * @memberof TopicDetails
     */
    segmentCount?: number;
    /**
     * 
     * @type {number}
     * @memberof TopicDetails
     */
    underReplicatedPartitions?: number;
    /**
     * 
     * @type {CleanUpPolicy}
     * @memberof TopicDetails
     */
    cleanUpPolicy?: CleanUpPolicy;
}

export function TopicDetailsFromJSON(json: any): TopicDetails {
    return TopicDetailsFromJSONTyped(json, false);
}

export function TopicDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopicDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'internal': !exists(json, 'internal') ? undefined : json['internal'],
        'partitions': !exists(json, 'partitions') ? undefined : ((json['partitions'] as Array<any>).map(PartitionFromJSON)),
        'partitionCount': !exists(json, 'partitionCount') ? undefined : json['partitionCount'],
        'replicationFactor': !exists(json, 'replicationFactor') ? undefined : json['replicationFactor'],
        'replicas': !exists(json, 'replicas') ? undefined : json['replicas'],
        'inSyncReplicas': !exists(json, 'inSyncReplicas') ? undefined : json['inSyncReplicas'],
        'bytesInPerSec': !exists(json, 'bytesInPerSec') ? undefined : json['bytesInPerSec'],
        'bytesOutPerSec': !exists(json, 'bytesOutPerSec') ? undefined : json['bytesOutPerSec'],
        'segmentSize': !exists(json, 'segmentSize') ? undefined : json['segmentSize'],
        'segmentCount': !exists(json, 'segmentCount') ? undefined : json['segmentCount'],
        'underReplicatedPartitions': !exists(json, 'underReplicatedPartitions') ? undefined : json['underReplicatedPartitions'],
        'cleanUpPolicy': !exists(json, 'cleanUpPolicy') ? undefined : CleanUpPolicyFromJSON(json['cleanUpPolicy']),
    };
}

export function TopicDetailsToJSON(value?: TopicDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'internal': value.internal,
        'partitions': value.partitions === undefined ? undefined : ((value.partitions as Array<any>).map(PartitionToJSON)),
        'partitionCount': value.partitionCount,
        'replicationFactor': value.replicationFactor,
        'replicas': value.replicas,
        'inSyncReplicas': value.inSyncReplicas,
        'bytesInPerSec': value.bytesInPerSec,
        'bytesOutPerSec': value.bytesOutPerSec,
        'segmentSize': value.segmentSize,
        'segmentCount': value.segmentCount,
        'underReplicatedPartitions': value.underReplicatedPartitions,
        'cleanUpPolicy': CleanUpPolicyToJSON(value.cleanUpPolicy),
    };
}

