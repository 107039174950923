/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ConsumerGroupOffsetsResetType {
    EARLIEST = 'EARLIEST',
    LATEST = 'LATEST',
    TIMESTAMP = 'TIMESTAMP',
    OFFSET = 'OFFSET'
}

export function ConsumerGroupOffsetsResetTypeFromJSON(json: any): ConsumerGroupOffsetsResetType {
    return ConsumerGroupOffsetsResetTypeFromJSONTyped(json, false);
}

export function ConsumerGroupOffsetsResetTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsumerGroupOffsetsResetType {
    return json as ConsumerGroupOffsetsResetType;
}

export function ConsumerGroupOffsetsResetTypeToJSON(value?: ConsumerGroupOffsetsResetType | null): any {
    return value as any;
}

