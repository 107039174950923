/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Connect,
    ConnectFromJSON,
    ConnectToJSON,
    Connector,
    ConnectorFromJSON,
    ConnectorToJSON,
    ConnectorAction,
    ConnectorActionFromJSON,
    ConnectorActionToJSON,
    ConnectorPlugin,
    ConnectorPluginFromJSON,
    ConnectorPluginToJSON,
    ConnectorPluginConfigValidationResponse,
    ConnectorPluginConfigValidationResponseFromJSON,
    ConnectorPluginConfigValidationResponseToJSON,
    FullConnectorInfo,
    FullConnectorInfoFromJSON,
    FullConnectorInfoToJSON,
    NewConnector,
    NewConnectorFromJSON,
    NewConnectorToJSON,
    Task,
    TaskFromJSON,
    TaskToJSON,
} from '../models';

export interface CreateConnectorRequest {
    clusterName: string;
    connectName: string;
    newConnector?: NewConnector;
}

export interface DeleteConnectorRequest {
    clusterName: string;
    connectName: string;
    connectorName: string;
}

export interface GetAllConnectorsRequest {
    clusterName: string;
    search?: string;
}

export interface GetConnectorRequest {
    clusterName: string;
    connectName: string;
    connectorName: string;
}

export interface GetConnectorConfigRequest {
    clusterName: string;
    connectName: string;
    connectorName: string;
}

export interface GetConnectorPluginsRequest {
    clusterName: string;
    connectName: string;
}

export interface GetConnectorTasksRequest {
    clusterName: string;
    connectName: string;
    connectorName: string;
}

export interface GetConnectorsRequest {
    clusterName: string;
    connectName: string;
}

export interface GetConnectsRequest {
    clusterName: string;
}

export interface RestartConnectorTaskRequest {
    clusterName: string;
    connectName: string;
    connectorName: string;
    taskId: number;
}

export interface SetConnectorConfigRequest {
    clusterName: string;
    connectName: string;
    connectorName: string;
    requestBody?: { [key: string]: any | undefined; };
}

export interface UpdateConnectorStateRequest {
    clusterName: string;
    connectName: string;
    connectorName: string;
    action: ConnectorAction;
}

export interface ValidateConnectorPluginConfigRequest {
    clusterName: string;
    connectName: string;
    pluginName: string;
    requestBody?: { [key: string]: any | undefined; };
}

/**
 * KafkaConnectApi - interface
 * 
 * @export
 * @interface KafkaConnectApiInterface
 */
export interface KafkaConnectApiInterface {
    /**
     * 
     * @summary create new connector
     * @param {string} clusterName 
     * @param {string} connectName 
     * @param {NewConnector} [newConnector] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaConnectApiInterface
     */
    createConnectorRaw(requestParameters: CreateConnectorRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Connector>>;

    /**
     * create new connector
     */
    createConnector(requestParameters: CreateConnectorRequest, initOverrides?: RequestInit): Promise<Connector>;

    /**
     * 
     * @summary delete connector
     * @param {string} clusterName 
     * @param {string} connectName 
     * @param {string} connectorName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaConnectApiInterface
     */
    deleteConnectorRaw(requestParameters: DeleteConnectorRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * delete connector
     */
    deleteConnector(requestParameters: DeleteConnectorRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary get filtered kafka connectors
     * @param {string} clusterName 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaConnectApiInterface
     */
    getAllConnectorsRaw(requestParameters: GetAllConnectorsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<FullConnectorInfo>>>;

    /**
     * get filtered kafka connectors
     */
    getAllConnectors(requestParameters: GetAllConnectorsRequest, initOverrides?: RequestInit): Promise<Array<FullConnectorInfo>>;

    /**
     * 
     * @summary get information about the connector
     * @param {string} clusterName 
     * @param {string} connectName 
     * @param {string} connectorName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaConnectApiInterface
     */
    getConnectorRaw(requestParameters: GetConnectorRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Connector>>;

    /**
     * get information about the connector
     */
    getConnector(requestParameters: GetConnectorRequest, initOverrides?: RequestInit): Promise<Connector>;

    /**
     * 
     * @summary get connector configuration
     * @param {string} clusterName 
     * @param {string} connectName 
     * @param {string} connectorName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaConnectApiInterface
     */
    getConnectorConfigRaw(requestParameters: GetConnectorConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<{ [key: string]: any | undefined; }>>;

    /**
     * get connector configuration
     */
    getConnectorConfig(requestParameters: GetConnectorConfigRequest, initOverrides?: RequestInit): Promise<{ [key: string]: any | undefined; }>;

    /**
     * 
     * @summary get connector plugins
     * @param {string} clusterName 
     * @param {string} connectName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaConnectApiInterface
     */
    getConnectorPluginsRaw(requestParameters: GetConnectorPluginsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ConnectorPlugin>>>;

    /**
     * get connector plugins
     */
    getConnectorPlugins(requestParameters: GetConnectorPluginsRequest, initOverrides?: RequestInit): Promise<Array<ConnectorPlugin>>;

    /**
     * 
     * @summary get connector tasks
     * @param {string} clusterName 
     * @param {string} connectName 
     * @param {string} connectorName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaConnectApiInterface
     */
    getConnectorTasksRaw(requestParameters: GetConnectorTasksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Task>>>;

    /**
     * get connector tasks
     */
    getConnectorTasks(requestParameters: GetConnectorTasksRequest, initOverrides?: RequestInit): Promise<Array<Task>>;

    /**
     * 
     * @summary get connectors for provided kafka connect instance
     * @param {string} clusterName 
     * @param {string} connectName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaConnectApiInterface
     */
    getConnectorsRaw(requestParameters: GetConnectorsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<string>>>;

    /**
     * get connectors for provided kafka connect instance
     */
    getConnectors(requestParameters: GetConnectorsRequest, initOverrides?: RequestInit): Promise<Array<string>>;

    /**
     * 
     * @summary get all kafka connect instances
     * @param {string} clusterName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaConnectApiInterface
     */
    getConnectsRaw(requestParameters: GetConnectsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Connect>>>;

    /**
     * get all kafka connect instances
     */
    getConnects(requestParameters: GetConnectsRequest, initOverrides?: RequestInit): Promise<Array<Connect>>;

    /**
     * 
     * @summary restart connector task
     * @param {string} clusterName 
     * @param {string} connectName 
     * @param {string} connectorName 
     * @param {number} taskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaConnectApiInterface
     */
    restartConnectorTaskRaw(requestParameters: RestartConnectorTaskRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * restart connector task
     */
    restartConnectorTask(requestParameters: RestartConnectorTaskRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary update or create connector with provided config
     * @param {string} clusterName 
     * @param {string} connectName 
     * @param {string} connectorName 
     * @param {{ [key: string]: any | undefined; }} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaConnectApiInterface
     */
    setConnectorConfigRaw(requestParameters: SetConnectorConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Connector>>;

    /**
     * update or create connector with provided config
     */
    setConnectorConfig(requestParameters: SetConnectorConfigRequest, initOverrides?: RequestInit): Promise<Connector>;

    /**
     * 
     * @summary update connector state (restart, pause or resume)
     * @param {string} clusterName 
     * @param {string} connectName 
     * @param {string} connectorName 
     * @param {ConnectorAction} action 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaConnectApiInterface
     */
    updateConnectorStateRaw(requestParameters: UpdateConnectorStateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * update connector state (restart, pause or resume)
     */
    updateConnectorState(requestParameters: UpdateConnectorStateRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary validate connector plugin configuration
     * @param {string} clusterName 
     * @param {string} connectName 
     * @param {string} pluginName 
     * @param {{ [key: string]: any | undefined; }} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaConnectApiInterface
     */
    validateConnectorPluginConfigRaw(requestParameters: ValidateConnectorPluginConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ConnectorPluginConfigValidationResponse>>;

    /**
     * validate connector plugin configuration
     */
    validateConnectorPluginConfig(requestParameters: ValidateConnectorPluginConfigRequest, initOverrides?: RequestInit): Promise<ConnectorPluginConfigValidationResponse>;

}

/**
 * 
 */
export class KafkaConnectApi extends runtime.BaseAPI implements KafkaConnectApiInterface {

    /**
     * create new connector
     */
    async createConnectorRaw(requestParameters: CreateConnectorRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Connector>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling createConnector.');
        }

        if (requestParameters.connectName === null || requestParameters.connectName === undefined) {
            throw new runtime.RequiredError('connectName','Required parameter requestParameters.connectName was null or undefined when calling createConnector.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/clusters/{clusterName}/connects/{connectName}/connectors`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"connectName"}}`, encodeURIComponent(String(requestParameters.connectName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewConnectorToJSON(requestParameters.newConnector),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectorFromJSON(jsonValue));
    }

    /**
     * create new connector
     */
    async createConnector(requestParameters: CreateConnectorRequest, initOverrides?: RequestInit): Promise<Connector> {
        const response = await this.createConnectorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * delete connector
     */
    async deleteConnectorRaw(requestParameters: DeleteConnectorRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling deleteConnector.');
        }

        if (requestParameters.connectName === null || requestParameters.connectName === undefined) {
            throw new runtime.RequiredError('connectName','Required parameter requestParameters.connectName was null or undefined when calling deleteConnector.');
        }

        if (requestParameters.connectorName === null || requestParameters.connectorName === undefined) {
            throw new runtime.RequiredError('connectorName','Required parameter requestParameters.connectorName was null or undefined when calling deleteConnector.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/connects/{connectName}/connectors/{connectorName}`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"connectName"}}`, encodeURIComponent(String(requestParameters.connectName))).replace(`{${"connectorName"}}`, encodeURIComponent(String(requestParameters.connectorName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete connector
     */
    async deleteConnector(requestParameters: DeleteConnectorRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteConnectorRaw(requestParameters, initOverrides);
    }

    /**
     * get filtered kafka connectors
     */
    async getAllConnectorsRaw(requestParameters: GetAllConnectorsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<FullConnectorInfo>>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling getAllConnectors.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/connectors`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FullConnectorInfoFromJSON));
    }

    /**
     * get filtered kafka connectors
     */
    async getAllConnectors(requestParameters: GetAllConnectorsRequest, initOverrides?: RequestInit): Promise<Array<FullConnectorInfo>> {
        const response = await this.getAllConnectorsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get information about the connector
     */
    async getConnectorRaw(requestParameters: GetConnectorRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Connector>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling getConnector.');
        }

        if (requestParameters.connectName === null || requestParameters.connectName === undefined) {
            throw new runtime.RequiredError('connectName','Required parameter requestParameters.connectName was null or undefined when calling getConnector.');
        }

        if (requestParameters.connectorName === null || requestParameters.connectorName === undefined) {
            throw new runtime.RequiredError('connectorName','Required parameter requestParameters.connectorName was null or undefined when calling getConnector.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/connects/{connectName}/connectors/{connectorName}`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"connectName"}}`, encodeURIComponent(String(requestParameters.connectName))).replace(`{${"connectorName"}}`, encodeURIComponent(String(requestParameters.connectorName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectorFromJSON(jsonValue));
    }

    /**
     * get information about the connector
     */
    async getConnector(requestParameters: GetConnectorRequest, initOverrides?: RequestInit): Promise<Connector> {
        const response = await this.getConnectorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get connector configuration
     */
    async getConnectorConfigRaw(requestParameters: GetConnectorConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<{ [key: string]: any | undefined; }>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling getConnectorConfig.');
        }

        if (requestParameters.connectName === null || requestParameters.connectName === undefined) {
            throw new runtime.RequiredError('connectName','Required parameter requestParameters.connectName was null or undefined when calling getConnectorConfig.');
        }

        if (requestParameters.connectorName === null || requestParameters.connectorName === undefined) {
            throw new runtime.RequiredError('connectorName','Required parameter requestParameters.connectorName was null or undefined when calling getConnectorConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/connects/{connectName}/connectors/{connectorName}/config`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"connectName"}}`, encodeURIComponent(String(requestParameters.connectName))).replace(`{${"connectorName"}}`, encodeURIComponent(String(requestParameters.connectorName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * get connector configuration
     */
    async getConnectorConfig(requestParameters: GetConnectorConfigRequest, initOverrides?: RequestInit): Promise<{ [key: string]: any | undefined; }> {
        const response = await this.getConnectorConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get connector plugins
     */
    async getConnectorPluginsRaw(requestParameters: GetConnectorPluginsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ConnectorPlugin>>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling getConnectorPlugins.');
        }

        if (requestParameters.connectName === null || requestParameters.connectName === undefined) {
            throw new runtime.RequiredError('connectName','Required parameter requestParameters.connectName was null or undefined when calling getConnectorPlugins.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/connects/{connectName}/plugins`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"connectName"}}`, encodeURIComponent(String(requestParameters.connectName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ConnectorPluginFromJSON));
    }

    /**
     * get connector plugins
     */
    async getConnectorPlugins(requestParameters: GetConnectorPluginsRequest, initOverrides?: RequestInit): Promise<Array<ConnectorPlugin>> {
        const response = await this.getConnectorPluginsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get connector tasks
     */
    async getConnectorTasksRaw(requestParameters: GetConnectorTasksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Task>>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling getConnectorTasks.');
        }

        if (requestParameters.connectName === null || requestParameters.connectName === undefined) {
            throw new runtime.RequiredError('connectName','Required parameter requestParameters.connectName was null or undefined when calling getConnectorTasks.');
        }

        if (requestParameters.connectorName === null || requestParameters.connectorName === undefined) {
            throw new runtime.RequiredError('connectorName','Required parameter requestParameters.connectorName was null or undefined when calling getConnectorTasks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/connects/{connectName}/connectors/{connectorName}/tasks`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"connectName"}}`, encodeURIComponent(String(requestParameters.connectName))).replace(`{${"connectorName"}}`, encodeURIComponent(String(requestParameters.connectorName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TaskFromJSON));
    }

    /**
     * get connector tasks
     */
    async getConnectorTasks(requestParameters: GetConnectorTasksRequest, initOverrides?: RequestInit): Promise<Array<Task>> {
        const response = await this.getConnectorTasksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get connectors for provided kafka connect instance
     */
    async getConnectorsRaw(requestParameters: GetConnectorsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling getConnectors.');
        }

        if (requestParameters.connectName === null || requestParameters.connectName === undefined) {
            throw new runtime.RequiredError('connectName','Required parameter requestParameters.connectName was null or undefined when calling getConnectors.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/connects/{connectName}/connectors`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"connectName"}}`, encodeURIComponent(String(requestParameters.connectName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * get connectors for provided kafka connect instance
     */
    async getConnectors(requestParameters: GetConnectorsRequest, initOverrides?: RequestInit): Promise<Array<string>> {
        const response = await this.getConnectorsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get all kafka connect instances
     */
    async getConnectsRaw(requestParameters: GetConnectsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Connect>>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling getConnects.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/connects`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ConnectFromJSON));
    }

    /**
     * get all kafka connect instances
     */
    async getConnects(requestParameters: GetConnectsRequest, initOverrides?: RequestInit): Promise<Array<Connect>> {
        const response = await this.getConnectsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * restart connector task
     */
    async restartConnectorTaskRaw(requestParameters: RestartConnectorTaskRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling restartConnectorTask.');
        }

        if (requestParameters.connectName === null || requestParameters.connectName === undefined) {
            throw new runtime.RequiredError('connectName','Required parameter requestParameters.connectName was null or undefined when calling restartConnectorTask.');
        }

        if (requestParameters.connectorName === null || requestParameters.connectorName === undefined) {
            throw new runtime.RequiredError('connectorName','Required parameter requestParameters.connectorName was null or undefined when calling restartConnectorTask.');
        }

        if (requestParameters.taskId === null || requestParameters.taskId === undefined) {
            throw new runtime.RequiredError('taskId','Required parameter requestParameters.taskId was null or undefined when calling restartConnectorTask.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/connects/{connectName}/connectors/{connectorName}/tasks/{taskId}/action/restart`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"connectName"}}`, encodeURIComponent(String(requestParameters.connectName))).replace(`{${"connectorName"}}`, encodeURIComponent(String(requestParameters.connectorName))).replace(`{${"taskId"}}`, encodeURIComponent(String(requestParameters.taskId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * restart connector task
     */
    async restartConnectorTask(requestParameters: RestartConnectorTaskRequest, initOverrides?: RequestInit): Promise<void> {
        await this.restartConnectorTaskRaw(requestParameters, initOverrides);
    }

    /**
     * update or create connector with provided config
     */
    async setConnectorConfigRaw(requestParameters: SetConnectorConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Connector>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling setConnectorConfig.');
        }

        if (requestParameters.connectName === null || requestParameters.connectName === undefined) {
            throw new runtime.RequiredError('connectName','Required parameter requestParameters.connectName was null or undefined when calling setConnectorConfig.');
        }

        if (requestParameters.connectorName === null || requestParameters.connectorName === undefined) {
            throw new runtime.RequiredError('connectorName','Required parameter requestParameters.connectorName was null or undefined when calling setConnectorConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/clusters/{clusterName}/connects/{connectName}/connectors/{connectorName}/config`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"connectName"}}`, encodeURIComponent(String(requestParameters.connectName))).replace(`{${"connectorName"}}`, encodeURIComponent(String(requestParameters.connectorName))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectorFromJSON(jsonValue));
    }

    /**
     * update or create connector with provided config
     */
    async setConnectorConfig(requestParameters: SetConnectorConfigRequest, initOverrides?: RequestInit): Promise<Connector> {
        const response = await this.setConnectorConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update connector state (restart, pause or resume)
     */
    async updateConnectorStateRaw(requestParameters: UpdateConnectorStateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling updateConnectorState.');
        }

        if (requestParameters.connectName === null || requestParameters.connectName === undefined) {
            throw new runtime.RequiredError('connectName','Required parameter requestParameters.connectName was null or undefined when calling updateConnectorState.');
        }

        if (requestParameters.connectorName === null || requestParameters.connectorName === undefined) {
            throw new runtime.RequiredError('connectorName','Required parameter requestParameters.connectorName was null or undefined when calling updateConnectorState.');
        }

        if (requestParameters.action === null || requestParameters.action === undefined) {
            throw new runtime.RequiredError('action','Required parameter requestParameters.action was null or undefined when calling updateConnectorState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/connects/{connectName}/connectors/{connectorName}/action/{action}`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"connectName"}}`, encodeURIComponent(String(requestParameters.connectName))).replace(`{${"connectorName"}}`, encodeURIComponent(String(requestParameters.connectorName))).replace(`{${"action"}}`, encodeURIComponent(String(requestParameters.action))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * update connector state (restart, pause or resume)
     */
    async updateConnectorState(requestParameters: UpdateConnectorStateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateConnectorStateRaw(requestParameters, initOverrides);
    }

    /**
     * validate connector plugin configuration
     */
    async validateConnectorPluginConfigRaw(requestParameters: ValidateConnectorPluginConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ConnectorPluginConfigValidationResponse>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling validateConnectorPluginConfig.');
        }

        if (requestParameters.connectName === null || requestParameters.connectName === undefined) {
            throw new runtime.RequiredError('connectName','Required parameter requestParameters.connectName was null or undefined when calling validateConnectorPluginConfig.');
        }

        if (requestParameters.pluginName === null || requestParameters.pluginName === undefined) {
            throw new runtime.RequiredError('pluginName','Required parameter requestParameters.pluginName was null or undefined when calling validateConnectorPluginConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/clusters/{clusterName}/connects/{connectName}/plugins/{pluginName}/config/validate`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"connectName"}}`, encodeURIComponent(String(requestParameters.connectName))).replace(`{${"pluginName"}}`, encodeURIComponent(String(requestParameters.pluginName))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectorPluginConfigValidationResponseFromJSON(jsonValue));
    }

    /**
     * validate connector plugin configuration
     */
    async validateConnectorPluginConfig(requestParameters: ValidateConnectorPluginConfigRequest, initOverrides?: RequestInit): Promise<ConnectorPluginConfigValidationResponse> {
        const response = await this.validateConnectorPluginConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
