/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BrokerLogdirUpdate
 */
export interface BrokerLogdirUpdate {
    /**
     * 
     * @type {string}
     * @memberof BrokerLogdirUpdate
     */
    topic?: string;
    /**
     * 
     * @type {number}
     * @memberof BrokerLogdirUpdate
     */
    partition?: number;
    /**
     * 
     * @type {string}
     * @memberof BrokerLogdirUpdate
     */
    logDir?: string;
}

export function BrokerLogdirUpdateFromJSON(json: any): BrokerLogdirUpdate {
    return BrokerLogdirUpdateFromJSONTyped(json, false);
}

export function BrokerLogdirUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrokerLogdirUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'topic': !exists(json, 'topic') ? undefined : json['topic'],
        'partition': !exists(json, 'partition') ? undefined : json['partition'],
        'logDir': !exists(json, 'logDir') ? undefined : json['logDir'],
    };
}

export function BrokerLogdirUpdateToJSON(value?: BrokerLogdirUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'topic': value.topic,
        'partition': value.partition,
        'logDir': value.logDir,
    };
}

