/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ConfigSource {
    DYNAMIC_TOPIC_CONFIG = 'DYNAMIC_TOPIC_CONFIG',
    DYNAMIC_BROKER_LOGGER_CONFIG = 'DYNAMIC_BROKER_LOGGER_CONFIG',
    DYNAMIC_BROKER_CONFIG = 'DYNAMIC_BROKER_CONFIG',
    DYNAMIC_DEFAULT_BROKER_CONFIG = 'DYNAMIC_DEFAULT_BROKER_CONFIG',
    STATIC_BROKER_CONFIG = 'STATIC_BROKER_CONFIG',
    DEFAULT_CONFIG = 'DEFAULT_CONFIG',
    UNKNOWN = 'UNKNOWN'
}

export function ConfigSourceFromJSON(json: any): ConfigSource {
    return ConfigSourceFromJSONTyped(json, false);
}

export function ConfigSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigSource {
    return json as ConfigSource;
}

export function ConfigSourceToJSON(value?: ConfigSource | null): any {
    return value as any;
}

