/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TopicMessageConsuming
 */
export interface TopicMessageConsuming {
    /**
     * 
     * @type {number}
     * @memberof TopicMessageConsuming
     */
    bytesConsumed?: number;
    /**
     * 
     * @type {number}
     * @memberof TopicMessageConsuming
     */
    elapsedMs?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TopicMessageConsuming
     */
    isCancelled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TopicMessageConsuming
     */
    messagesConsumed?: number;
}

export function TopicMessageConsumingFromJSON(json: any): TopicMessageConsuming {
    return TopicMessageConsumingFromJSONTyped(json, false);
}

export function TopicMessageConsumingFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopicMessageConsuming {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bytesConsumed': !exists(json, 'bytesConsumed') ? undefined : json['bytesConsumed'],
        'elapsedMs': !exists(json, 'elapsedMs') ? undefined : json['elapsedMs'],
        'isCancelled': !exists(json, 'isCancelled') ? undefined : json['isCancelled'],
        'messagesConsumed': !exists(json, 'messagesConsumed') ? undefined : json['messagesConsumed'],
    };
}

export function TopicMessageConsumingToJSON(value?: TopicMessageConsuming | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bytesConsumed': value.bytesConsumed,
        'elapsedMs': value.elapsedMs,
        'isCancelled': value.isCancelled,
        'messagesConsumed': value.messagesConsumed,
    };
}

