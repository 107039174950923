/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MessageFormat {
    AVRO = 'AVRO',
    JSON = 'JSON',
    PROTOBUF = 'PROTOBUF',
    UNKNOWN = 'UNKNOWN'
}

export function MessageFormatFromJSON(json: any): MessageFormat {
    return MessageFormatFromJSONTyped(json, false);
}

export function MessageFormatFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageFormat {
    return json as MessageFormat;
}

export function MessageFormatToJSON(value?: MessageFormat | null): any {
    return value as any;
}

