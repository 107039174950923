/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConfigSource,
    ConfigSourceFromJSON,
    ConfigSourceFromJSONTyped,
    ConfigSourceToJSON,
    ConfigSynonym,
    ConfigSynonymFromJSON,
    ConfigSynonymFromJSONTyped,
    ConfigSynonymToJSON,
} from './';

/**
 * 
 * @export
 * @interface TopicConfig
 */
export interface TopicConfig {
    /**
     * 
     * @type {string}
     * @memberof TopicConfig
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TopicConfig
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof TopicConfig
     */
    defaultValue?: string;
    /**
     * 
     * @type {ConfigSource}
     * @memberof TopicConfig
     */
    source?: ConfigSource;
    /**
     * 
     * @type {boolean}
     * @memberof TopicConfig
     */
    isSensitive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TopicConfig
     */
    isReadOnly?: boolean;
    /**
     * 
     * @type {Array<ConfigSynonym>}
     * @memberof TopicConfig
     */
    synonyms?: Array<ConfigSynonym>;
}

export function TopicConfigFromJSON(json: any): TopicConfig {
    return TopicConfigFromJSONTyped(json, false);
}

export function TopicConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopicConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'defaultValue': !exists(json, 'defaultValue') ? undefined : json['defaultValue'],
        'source': !exists(json, 'source') ? undefined : ConfigSourceFromJSON(json['source']),
        'isSensitive': !exists(json, 'isSensitive') ? undefined : json['isSensitive'],
        'isReadOnly': !exists(json, 'isReadOnly') ? undefined : json['isReadOnly'],
        'synonyms': !exists(json, 'synonyms') ? undefined : ((json['synonyms'] as Array<any>).map(ConfigSynonymFromJSON)),
    };
}

export function TopicConfigToJSON(value?: TopicConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'value': value.value,
        'defaultValue': value.defaultValue,
        'source': ConfigSourceToJSON(value.source),
        'isSensitive': value.isSensitive,
        'isReadOnly': value.isReadOnly,
        'synonyms': value.synonyms === undefined ? undefined : ((value.synonyms as Array<any>).map(ConfigSynonymToJSON)),
    };
}

