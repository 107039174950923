/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompatibilityLevel
 */
export interface CompatibilityLevel {
    /**
     * 
     * @type {string}
     * @memberof CompatibilityLevel
     */
    compatibility: CompatibilityLevelCompatibilityEnum;
}

/**
* @export
* @enum {string}
*/
export enum CompatibilityLevelCompatibilityEnum {
    BACKWARD = 'BACKWARD',
    BACKWARD_TRANSITIVE = 'BACKWARD_TRANSITIVE',
    FORWARD = 'FORWARD',
    FORWARD_TRANSITIVE = 'FORWARD_TRANSITIVE',
    FULL = 'FULL',
    FULL_TRANSITIVE = 'FULL_TRANSITIVE',
    NONE = 'NONE'
}

export function CompatibilityLevelFromJSON(json: any): CompatibilityLevel {
    return CompatibilityLevelFromJSONTyped(json, false);
}

export function CompatibilityLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompatibilityLevel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'compatibility': json['compatibility'],
    };
}

export function CompatibilityLevelToJSON(value?: CompatibilityLevel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'compatibility': value.compatibility,
    };
}

