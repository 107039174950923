/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConsumerGroupTopicPartition
 */
export interface ConsumerGroupTopicPartition {
    /**
     * 
     * @type {string}
     * @memberof ConsumerGroupTopicPartition
     */
    topic: string;
    /**
     * 
     * @type {number}
     * @memberof ConsumerGroupTopicPartition
     */
    partition: number;
    /**
     * 
     * @type {number}
     * @memberof ConsumerGroupTopicPartition
     */
    currentOffset?: number;
    /**
     * 
     * @type {number}
     * @memberof ConsumerGroupTopicPartition
     */
    endOffset?: number;
    /**
     * 
     * @type {number}
     * @memberof ConsumerGroupTopicPartition
     */
    messagesBehind?: number;
    /**
     * 
     * @type {string}
     * @memberof ConsumerGroupTopicPartition
     */
    consumerId?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsumerGroupTopicPartition
     */
    host?: string;
}

export function ConsumerGroupTopicPartitionFromJSON(json: any): ConsumerGroupTopicPartition {
    return ConsumerGroupTopicPartitionFromJSONTyped(json, false);
}

export function ConsumerGroupTopicPartitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsumerGroupTopicPartition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'topic': json['topic'],
        'partition': json['partition'],
        'currentOffset': !exists(json, 'currentOffset') ? undefined : json['currentOffset'],
        'endOffset': !exists(json, 'endOffset') ? undefined : json['endOffset'],
        'messagesBehind': !exists(json, 'messagesBehind') ? undefined : json['messagesBehind'],
        'consumerId': !exists(json, 'consumerId') ? undefined : json['consumerId'],
        'host': !exists(json, 'host') ? undefined : json['host'],
    };
}

export function ConsumerGroupTopicPartitionToJSON(value?: ConsumerGroupTopicPartition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'topic': value.topic,
        'partition': value.partition,
        'currentOffset': value.currentOffset,
        'endOffset': value.endOffset,
        'messagesBehind': value.messagesBehind,
        'consumerId': value.consumerId,
        'host': value.host,
    };
}

