/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Cluster,
    ClusterFromJSON,
    ClusterToJSON,
    ClusterMetrics,
    ClusterMetricsFromJSON,
    ClusterMetricsToJSON,
    ClusterStats,
    ClusterStatsFromJSON,
    ClusterStatsToJSON,
} from '../models';

export interface GetClusterMetricsRequest {
    clusterName: string;
}

export interface GetClusterStatsRequest {
    clusterName: string;
}

export interface UpdateClusterInfoRequest {
    clusterName: string;
}

/**
 * ClustersApi - interface
 * 
 * @export
 * @interface ClustersApiInterface
 */
export interface ClustersApiInterface {
    /**
     * 
     * @summary getClusterMetrics
     * @param {string} clusterName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApiInterface
     */
    getClusterMetricsRaw(requestParameters: GetClusterMetricsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ClusterMetrics>>;

    /**
     * getClusterMetrics
     */
    getClusterMetrics(requestParameters: GetClusterMetricsRequest, initOverrides?: RequestInit): Promise<ClusterMetrics>;

    /**
     * 
     * @summary getClusterStats
     * @param {string} clusterName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApiInterface
     */
    getClusterStatsRaw(requestParameters: GetClusterStatsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ClusterStats>>;

    /**
     * getClusterStats
     */
    getClusterStats(requestParameters: GetClusterStatsRequest, initOverrides?: RequestInit): Promise<ClusterStats>;

    /**
     * 
     * @summary getClusters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApiInterface
     */
    getClustersRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Cluster>>>;

    /**
     * getClusters
     */
    getClusters(initOverrides?: RequestInit): Promise<Array<Cluster>>;

    /**
     * 
     * @summary updateClusterInfo
     * @param {string} clusterName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApiInterface
     */
    updateClusterInfoRaw(requestParameters: UpdateClusterInfoRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Cluster>>;

    /**
     * updateClusterInfo
     */
    updateClusterInfo(requestParameters: UpdateClusterInfoRequest, initOverrides?: RequestInit): Promise<Cluster>;

}

/**
 * 
 */
export class ClustersApi extends runtime.BaseAPI implements ClustersApiInterface {

    /**
     * getClusterMetrics
     */
    async getClusterMetricsRaw(requestParameters: GetClusterMetricsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ClusterMetrics>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling getClusterMetrics.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/metrics`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClusterMetricsFromJSON(jsonValue));
    }

    /**
     * getClusterMetrics
     */
    async getClusterMetrics(requestParameters: GetClusterMetricsRequest, initOverrides?: RequestInit): Promise<ClusterMetrics> {
        const response = await this.getClusterMetricsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getClusterStats
     */
    async getClusterStatsRaw(requestParameters: GetClusterStatsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ClusterStats>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling getClusterStats.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/stats`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClusterStatsFromJSON(jsonValue));
    }

    /**
     * getClusterStats
     */
    async getClusterStats(requestParameters: GetClusterStatsRequest, initOverrides?: RequestInit): Promise<ClusterStats> {
        const response = await this.getClusterStatsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getClusters
     */
    async getClustersRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Cluster>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClusterFromJSON));
    }

    /**
     * getClusters
     */
    async getClusters(initOverrides?: RequestInit): Promise<Array<Cluster>> {
        const response = await this.getClustersRaw(initOverrides);
        return await response.value();
    }

    /**
     * updateClusterInfo
     */
    async updateClusterInfoRaw(requestParameters: UpdateClusterInfoRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Cluster>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling updateClusterInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/cache`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClusterFromJSON(jsonValue));
    }

    /**
     * updateClusterInfo
     */
    async updateClusterInfo(requestParameters: UpdateClusterInfoRequest, initOverrides?: RequestInit): Promise<Cluster> {
        const response = await this.updateClusterInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
