/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Replica
 */
export interface Replica {
    /**
     * 
     * @type {number}
     * @memberof Replica
     */
    broker?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Replica
     */
    leader?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Replica
     */
    inSync?: boolean;
}

export function ReplicaFromJSON(json: any): Replica {
    return ReplicaFromJSONTyped(json, false);
}

export function ReplicaFromJSONTyped(json: any, ignoreDiscriminator: boolean): Replica {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'broker': !exists(json, 'broker') ? undefined : json['broker'],
        'leader': !exists(json, 'leader') ? undefined : json['leader'],
        'inSync': !exists(json, 'inSync') ? undefined : json['inSync'],
    };
}

export function ReplicaToJSON(value?: Replica | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'broker': value.broker,
        'leader': value.leader,
        'inSync': value.inSync,
    };
}

