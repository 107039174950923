/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PartitionOffset
 */
export interface PartitionOffset {
    /**
     * 
     * @type {number}
     * @memberof PartitionOffset
     */
    partition: number;
    /**
     * 
     * @type {number}
     * @memberof PartitionOffset
     */
    offset?: number;
}

export function PartitionOffsetFromJSON(json: any): PartitionOffset {
    return PartitionOffsetFromJSONTyped(json, false);
}

export function PartitionOffsetFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartitionOffset {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'partition': json['partition'],
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
    };
}

export function PartitionOffsetToJSON(value?: PartitionOffset | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'partition': value.partition,
        'offset': value.offset,
    };
}

