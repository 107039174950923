/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BrokerTopicLogdirs,
    BrokerTopicLogdirsFromJSON,
    BrokerTopicLogdirsFromJSONTyped,
    BrokerTopicLogdirsToJSON,
} from './';

/**
 * 
 * @export
 * @interface BrokersLogdirs
 */
export interface BrokersLogdirs {
    /**
     * 
     * @type {string}
     * @memberof BrokersLogdirs
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof BrokersLogdirs
     */
    error?: string;
    /**
     * 
     * @type {Array<BrokerTopicLogdirs>}
     * @memberof BrokersLogdirs
     */
    topics?: Array<BrokerTopicLogdirs>;
}

export function BrokersLogdirsFromJSON(json: any): BrokersLogdirs {
    return BrokersLogdirsFromJSONTyped(json, false);
}

export function BrokersLogdirsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrokersLogdirs {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'topics': !exists(json, 'topics') ? undefined : ((json['topics'] as Array<any>).map(BrokerTopicLogdirsFromJSON)),
    };
}

export function BrokersLogdirsToJSON(value?: BrokersLogdirs | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'error': value.error,
        'topics': value.topics === undefined ? undefined : ((value.topics as Array<any>).map(BrokerTopicLogdirsToJSON)),
    };
}

