/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TopicMessage,
    TopicMessageFromJSON,
    TopicMessageFromJSONTyped,
    TopicMessageToJSON,
    TopicMessageConsuming,
    TopicMessageConsumingFromJSON,
    TopicMessageConsumingFromJSONTyped,
    TopicMessageConsumingToJSON,
    TopicMessagePhase,
    TopicMessagePhaseFromJSON,
    TopicMessagePhaseFromJSONTyped,
    TopicMessagePhaseToJSON,
} from './';

/**
 * 
 * @export
 * @interface TopicMessageEvent
 */
export interface TopicMessageEvent {
    /**
     * 
     * @type {string}
     * @memberof TopicMessageEvent
     */
    type?: TopicMessageEventTypeEnum;
    /**
     * 
     * @type {TopicMessage}
     * @memberof TopicMessageEvent
     */
    message?: TopicMessage;
    /**
     * 
     * @type {TopicMessagePhase}
     * @memberof TopicMessageEvent
     */
    phase?: TopicMessagePhase;
    /**
     * 
     * @type {TopicMessageConsuming}
     * @memberof TopicMessageEvent
     */
    consuming?: TopicMessageConsuming;
}

/**
* @export
* @enum {string}
*/
export enum TopicMessageEventTypeEnum {
    PHASE = 'PHASE',
    MESSAGE = 'MESSAGE',
    CONSUMING = 'CONSUMING',
    DONE = 'DONE',
    EMIT_THROTTLING = 'EMIT_THROTTLING'
}

export function TopicMessageEventFromJSON(json: any): TopicMessageEvent {
    return TopicMessageEventFromJSONTyped(json, false);
}

export function TopicMessageEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopicMessageEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'message': !exists(json, 'message') ? undefined : TopicMessageFromJSON(json['message']),
        'phase': !exists(json, 'phase') ? undefined : TopicMessagePhaseFromJSON(json['phase']),
        'consuming': !exists(json, 'consuming') ? undefined : TopicMessageConsumingFromJSON(json['consuming']),
    };
}

export function TopicMessageEventToJSON(value?: TopicMessageEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'message': TopicMessageToJSON(value.message),
        'phase': TopicMessagePhaseToJSON(value.phase),
        'consuming': TopicMessageConsumingToJSON(value.consuming),
    };
}

