/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConfigSource,
    ConfigSourceFromJSON,
    ConfigSourceFromJSONTyped,
    ConfigSourceToJSON,
    ConfigSynonym,
    ConfigSynonymFromJSON,
    ConfigSynonymFromJSONTyped,
    ConfigSynonymToJSON,
} from './';

/**
 * 
 * @export
 * @interface BrokerConfig
 */
export interface BrokerConfig {
    /**
     * 
     * @type {string}
     * @memberof BrokerConfig
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BrokerConfig
     */
    value: string;
    /**
     * 
     * @type {ConfigSource}
     * @memberof BrokerConfig
     */
    source: ConfigSource;
    /**
     * 
     * @type {boolean}
     * @memberof BrokerConfig
     */
    isSensitive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BrokerConfig
     */
    isReadOnly: boolean;
    /**
     * 
     * @type {Array<ConfigSynonym>}
     * @memberof BrokerConfig
     */
    synonyms?: Array<ConfigSynonym>;
}

export function BrokerConfigFromJSON(json: any): BrokerConfig {
    return BrokerConfigFromJSONTyped(json, false);
}

export function BrokerConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrokerConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'value': json['value'],
        'source': ConfigSourceFromJSON(json['source']),
        'isSensitive': json['isSensitive'],
        'isReadOnly': json['isReadOnly'],
        'synonyms': !exists(json, 'synonyms') ? undefined : ((json['synonyms'] as Array<any>).map(ConfigSynonymFromJSON)),
    };
}

export function BrokerConfigToJSON(value?: BrokerConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'value': value.value,
        'source': ConfigSourceToJSON(value.source),
        'isSensitive': value.isSensitive,
        'isReadOnly': value.isReadOnly,
        'synonyms': value.synonyms === undefined ? undefined : ((value.synonyms as Array<any>).map(ConfigSynonymToJSON)),
    };
}

