/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SchemaType {
    AVRO = 'AVRO',
    JSON = 'JSON',
    PROTOBUF = 'PROTOBUF'
}

export function SchemaTypeFromJSON(json: any): SchemaType {
    return SchemaTypeFromJSONTyped(json, false);
}

export function SchemaTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SchemaType {
    return json as SchemaType;
}

export function SchemaTypeToJSON(value?: SchemaType | null): any {
    return value as any;
}

