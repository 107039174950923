/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ConsumerGroupOrdering {
    NAME = 'NAME',
    MEMBERS = 'MEMBERS',
    STATE = 'STATE'
}

export function ConsumerGroupOrderingFromJSON(json: any): ConsumerGroupOrdering {
    return ConsumerGroupOrderingFromJSONTyped(json, false);
}

export function ConsumerGroupOrderingFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsumerGroupOrdering {
    return json as ConsumerGroupOrdering;
}

export function ConsumerGroupOrderingToJSON(value?: ConsumerGroupOrdering | null): any {
    return value as any;
}

