/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CleanUpPolicy {
    DELETE = 'DELETE',
    COMPACT = 'COMPACT',
    COMPACT_DELETE = 'COMPACT_DELETE',
    UNKNOWN = 'UNKNOWN'
}

export function CleanUpPolicyFromJSON(json: any): CleanUpPolicy {
    return CleanUpPolicyFromJSONTyped(json, false);
}

export function CleanUpPolicyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CleanUpPolicy {
    return json as CleanUpPolicy;
}

export function CleanUpPolicyToJSON(value?: CleanUpPolicy | null): any {
    return value as any;
}

