/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BrokerDiskUsage
 */
export interface BrokerDiskUsage {
    /**
     * 
     * @type {number}
     * @memberof BrokerDiskUsage
     */
    brokerId: number;
    /**
     * 
     * @type {number}
     * @memberof BrokerDiskUsage
     */
    segmentSize?: number;
    /**
     * 
     * @type {number}
     * @memberof BrokerDiskUsage
     */
    segmentCount?: number;
}

export function BrokerDiskUsageFromJSON(json: any): BrokerDiskUsage {
    return BrokerDiskUsageFromJSONTyped(json, false);
}

export function BrokerDiskUsageFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrokerDiskUsage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'brokerId': json['brokerId'],
        'segmentSize': !exists(json, 'segmentSize') ? undefined : json['segmentSize'],
        'segmentCount': !exists(json, 'segmentCount') ? undefined : json['segmentCount'],
    };
}

export function BrokerDiskUsageToJSON(value?: BrokerDiskUsage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'brokerId': value.brokerId,
        'segmentSize': value.segmentSize,
        'segmentCount': value.segmentCount,
    };
}

