/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Broker,
    BrokerFromJSON,
    BrokerFromJSONTyped,
    BrokerToJSON,
    ConsumerGroup,
    ConsumerGroupFromJSON,
    ConsumerGroupFromJSONTyped,
    ConsumerGroupToJSON,
    ConsumerGroupDetailsAllOf,
    ConsumerGroupDetailsAllOfFromJSON,
    ConsumerGroupDetailsAllOfFromJSONTyped,
    ConsumerGroupDetailsAllOfToJSON,
    ConsumerGroupState,
    ConsumerGroupStateFromJSON,
    ConsumerGroupStateFromJSONTyped,
    ConsumerGroupStateToJSON,
    ConsumerGroupTopicPartition,
    ConsumerGroupTopicPartitionFromJSON,
    ConsumerGroupTopicPartitionFromJSONTyped,
    ConsumerGroupTopicPartitionToJSON,
} from './';

/**
 * 
 * @export
 * @interface ConsumerGroupDetails
 */
export interface ConsumerGroupDetails {
    /**
     * 
     * @type {string}
     * @memberof ConsumerGroupDetails
     */
    groupId: string;
    /**
     * 
     * @type {number}
     * @memberof ConsumerGroupDetails
     */
    members?: number;
    /**
     * 
     * @type {number}
     * @memberof ConsumerGroupDetails
     */
    topics?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ConsumerGroupDetails
     */
    simple?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConsumerGroupDetails
     */
    partitionAssignor?: string;
    /**
     * 
     * @type {ConsumerGroupState}
     * @memberof ConsumerGroupDetails
     */
    state?: ConsumerGroupState;
    /**
     * 
     * @type {Broker}
     * @memberof ConsumerGroupDetails
     */
    coordinator?: Broker;
    /**
     * 
     * @type {number}
     * @memberof ConsumerGroupDetails
     */
    messagesBehind?: number;
    /**
     * 
     * @type {Array<ConsumerGroupTopicPartition>}
     * @memberof ConsumerGroupDetails
     */
    partitions?: Array<ConsumerGroupTopicPartition>;
}

export function ConsumerGroupDetailsFromJSON(json: any): ConsumerGroupDetails {
    return ConsumerGroupDetailsFromJSONTyped(json, false);
}

export function ConsumerGroupDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsumerGroupDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groupId': json['groupId'],
        'members': !exists(json, 'members') ? undefined : json['members'],
        'topics': !exists(json, 'topics') ? undefined : json['topics'],
        'simple': !exists(json, 'simple') ? undefined : json['simple'],
        'partitionAssignor': !exists(json, 'partitionAssignor') ? undefined : json['partitionAssignor'],
        'state': !exists(json, 'state') ? undefined : ConsumerGroupStateFromJSON(json['state']),
        'coordinator': !exists(json, 'coordinator') ? undefined : BrokerFromJSON(json['coordinator']),
        'messagesBehind': !exists(json, 'messagesBehind') ? undefined : json['messagesBehind'],
        'partitions': !exists(json, 'partitions') ? undefined : ((json['partitions'] as Array<any>).map(ConsumerGroupTopicPartitionFromJSON)),
    };
}

export function ConsumerGroupDetailsToJSON(value?: ConsumerGroupDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'groupId': value.groupId,
        'members': value.members,
        'topics': value.topics,
        'simple': value.simple,
        'partitionAssignor': value.partitionAssignor,
        'state': ConsumerGroupStateToJSON(value.state),
        'coordinator': BrokerToJSON(value.coordinator),
        'messagesBehind': value.messagesBehind,
        'partitions': value.partitions === undefined ? undefined : ((value.partitions as Array<any>).map(ConsumerGroupTopicPartitionToJSON)),
    };
}

