/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Replica,
    ReplicaFromJSON,
    ReplicaFromJSONTyped,
    ReplicaToJSON,
} from './';

/**
 * 
 * @export
 * @interface Partition
 */
export interface Partition {
    /**
     * 
     * @type {number}
     * @memberof Partition
     */
    partition: number;
    /**
     * 
     * @type {number}
     * @memberof Partition
     */
    leader?: number;
    /**
     * 
     * @type {Array<Replica>}
     * @memberof Partition
     */
    replicas?: Array<Replica>;
    /**
     * 
     * @type {number}
     * @memberof Partition
     */
    offsetMax: number;
    /**
     * 
     * @type {number}
     * @memberof Partition
     */
    offsetMin: number;
}

export function PartitionFromJSON(json: any): Partition {
    return PartitionFromJSONTyped(json, false);
}

export function PartitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Partition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'partition': json['partition'],
        'leader': !exists(json, 'leader') ? undefined : json['leader'],
        'replicas': !exists(json, 'replicas') ? undefined : ((json['replicas'] as Array<any>).map(ReplicaFromJSON)),
        'offsetMax': json['offsetMax'],
        'offsetMin': json['offsetMin'],
    };
}

export function PartitionToJSON(value?: Partition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'partition': value.partition,
        'leader': value.leader,
        'replicas': value.replicas === undefined ? undefined : ((value.replicas as Array<any>).map(ReplicaToJSON)),
        'offsetMax': value.offsetMax,
        'offsetMin': value.offsetMin,
    };
}

