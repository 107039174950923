/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Broker,
    BrokerFromJSON,
    BrokerToJSON,
    BrokerConfig,
    BrokerConfigFromJSON,
    BrokerConfigToJSON,
    BrokerConfigItem,
    BrokerConfigItemFromJSON,
    BrokerConfigItemToJSON,
    BrokerLogdirUpdate,
    BrokerLogdirUpdateFromJSON,
    BrokerLogdirUpdateToJSON,
    BrokerMetrics,
    BrokerMetricsFromJSON,
    BrokerMetricsToJSON,
    BrokersLogdirs,
    BrokersLogdirsFromJSON,
    BrokersLogdirsToJSON,
} from '../models';

export interface GetAllBrokersLogdirsRequest {
    clusterName: string;
    broker?: Array<number>;
}

export interface GetBrokerConfigRequest {
    clusterName: string;
    id: number;
}

export interface GetBrokersRequest {
    clusterName: string;
}

export interface GetBrokersMetricsRequest {
    clusterName: string;
    id: number;
}

export interface UpdateBrokerConfigByNameRequest {
    clusterName: string;
    id: number;
    name: string;
    brokerConfigItem?: BrokerConfigItem;
}

export interface UpdateBrokerTopicPartitionLogDirRequest {
    clusterName: string;
    id: number;
    brokerLogdirUpdate?: BrokerLogdirUpdate;
}

/**
 * BrokersApi - interface
 * 
 * @export
 * @interface BrokersApiInterface
 */
export interface BrokersApiInterface {
    /**
     * 
     * @summary getAllBrokersLogdirs
     * @param {string} clusterName 
     * @param {Array<number>} [broker] array of broker ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokersApiInterface
     */
    getAllBrokersLogdirsRaw(requestParameters: GetAllBrokersLogdirsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<BrokersLogdirs>>>;

    /**
     * getAllBrokersLogdirs
     */
    getAllBrokersLogdirs(requestParameters: GetAllBrokersLogdirsRequest, initOverrides?: RequestInit): Promise<Array<BrokersLogdirs>>;

    /**
     * 
     * @summary getBrokerConfig
     * @param {string} clusterName 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokersApiInterface
     */
    getBrokerConfigRaw(requestParameters: GetBrokerConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<BrokerConfig>>>;

    /**
     * getBrokerConfig
     */
    getBrokerConfig(requestParameters: GetBrokerConfigRequest, initOverrides?: RequestInit): Promise<Array<BrokerConfig>>;

    /**
     * 
     * @summary getBrokers
     * @param {string} clusterName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokersApiInterface
     */
    getBrokersRaw(requestParameters: GetBrokersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Broker>>>;

    /**
     * getBrokers
     */
    getBrokers(requestParameters: GetBrokersRequest, initOverrides?: RequestInit): Promise<Array<Broker>>;

    /**
     * 
     * @summary getBrokersMetrics
     * @param {string} clusterName 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokersApiInterface
     */
    getBrokersMetricsRaw(requestParameters: GetBrokersMetricsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BrokerMetrics>>;

    /**
     * getBrokersMetrics
     */
    getBrokersMetrics(requestParameters: GetBrokersMetricsRequest, initOverrides?: RequestInit): Promise<BrokerMetrics>;

    /**
     * 
     * @summary updateBrokerConfigByName
     * @param {string} clusterName 
     * @param {number} id 
     * @param {string} name 
     * @param {BrokerConfigItem} [brokerConfigItem] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokersApiInterface
     */
    updateBrokerConfigByNameRaw(requestParameters: UpdateBrokerConfigByNameRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * updateBrokerConfigByName
     */
    updateBrokerConfigByName(requestParameters: UpdateBrokerConfigByNameRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary updateBrokerTopicPartitionLogDir
     * @param {string} clusterName 
     * @param {number} id 
     * @param {BrokerLogdirUpdate} [brokerLogdirUpdate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokersApiInterface
     */
    updateBrokerTopicPartitionLogDirRaw(requestParameters: UpdateBrokerTopicPartitionLogDirRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * updateBrokerTopicPartitionLogDir
     */
    updateBrokerTopicPartitionLogDir(requestParameters: UpdateBrokerTopicPartitionLogDirRequest, initOverrides?: RequestInit): Promise<void>;

}

/**
 * 
 */
export class BrokersApi extends runtime.BaseAPI implements BrokersApiInterface {

    /**
     * getAllBrokersLogdirs
     */
    async getAllBrokersLogdirsRaw(requestParameters: GetAllBrokersLogdirsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<BrokersLogdirs>>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling getAllBrokersLogdirs.');
        }

        const queryParameters: any = {};

        if (requestParameters.broker) {
            queryParameters['broker'] = requestParameters.broker;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/brokers/logdirs`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BrokersLogdirsFromJSON));
    }

    /**
     * getAllBrokersLogdirs
     */
    async getAllBrokersLogdirs(requestParameters: GetAllBrokersLogdirsRequest, initOverrides?: RequestInit): Promise<Array<BrokersLogdirs>> {
        const response = await this.getAllBrokersLogdirsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getBrokerConfig
     */
    async getBrokerConfigRaw(requestParameters: GetBrokerConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<BrokerConfig>>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling getBrokerConfig.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBrokerConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/brokers/{id}/configs`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BrokerConfigFromJSON));
    }

    /**
     * getBrokerConfig
     */
    async getBrokerConfig(requestParameters: GetBrokerConfigRequest, initOverrides?: RequestInit): Promise<Array<BrokerConfig>> {
        const response = await this.getBrokerConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getBrokers
     */
    async getBrokersRaw(requestParameters: GetBrokersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Broker>>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling getBrokers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/brokers`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BrokerFromJSON));
    }

    /**
     * getBrokers
     */
    async getBrokers(requestParameters: GetBrokersRequest, initOverrides?: RequestInit): Promise<Array<Broker>> {
        const response = await this.getBrokersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getBrokersMetrics
     */
    async getBrokersMetricsRaw(requestParameters: GetBrokersMetricsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BrokerMetrics>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling getBrokersMetrics.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBrokersMetrics.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/brokers/{id}/metrics`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BrokerMetricsFromJSON(jsonValue));
    }

    /**
     * getBrokersMetrics
     */
    async getBrokersMetrics(requestParameters: GetBrokersMetricsRequest, initOverrides?: RequestInit): Promise<BrokerMetrics> {
        const response = await this.getBrokersMetricsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateBrokerConfigByName
     */
    async updateBrokerConfigByNameRaw(requestParameters: UpdateBrokerConfigByNameRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling updateBrokerConfigByName.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBrokerConfigByName.');
        }

        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling updateBrokerConfigByName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/clusters/{clusterName}/brokers/{id}/configs/{name}`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BrokerConfigItemToJSON(requestParameters.brokerConfigItem),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * updateBrokerConfigByName
     */
    async updateBrokerConfigByName(requestParameters: UpdateBrokerConfigByNameRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateBrokerConfigByNameRaw(requestParameters, initOverrides);
    }

    /**
     * updateBrokerTopicPartitionLogDir
     */
    async updateBrokerTopicPartitionLogDirRaw(requestParameters: UpdateBrokerTopicPartitionLogDirRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling updateBrokerTopicPartitionLogDir.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBrokerTopicPartitionLogDir.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/clusters/{clusterName}/brokers/{id}/logdirs`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: BrokerLogdirUpdateToJSON(requestParameters.brokerLogdirUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * updateBrokerTopicPartitionLogDir
     */
    async updateBrokerTopicPartitionLogDir(requestParameters: UpdateBrokerTopicPartitionLogDirRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateBrokerTopicPartitionLogDirRaw(requestParameters, initOverrides);
    }

}
