/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MetricsCollectionError,
    MetricsCollectionErrorFromJSON,
    MetricsCollectionErrorFromJSONTyped,
    MetricsCollectionErrorToJSON,
    ServerStatus,
    ServerStatusFromJSON,
    ServerStatusFromJSONTyped,
    ServerStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface Cluster
 */
export interface Cluster {
    /**
     * 
     * @type {string}
     * @memberof Cluster
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof Cluster
     */
    defaultCluster?: boolean;
    /**
     * 
     * @type {ServerStatus}
     * @memberof Cluster
     */
    status: ServerStatus;
    /**
     * 
     * @type {MetricsCollectionError}
     * @memberof Cluster
     */
    lastError?: MetricsCollectionError;
    /**
     * 
     * @type {number}
     * @memberof Cluster
     */
    brokerCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Cluster
     */
    onlinePartitionCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Cluster
     */
    topicCount?: number;
    /**
     * 
     * @type {number}
     * @memberof Cluster
     */
    bytesInPerSec?: number;
    /**
     * 
     * @type {number}
     * @memberof Cluster
     */
    bytesOutPerSec?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Cluster
     */
    readOnly?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Cluster
     */
    version?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Cluster
     */
    features?: Array<ClusterFeaturesEnum>;
}

/**
* @export
* @enum {string}
*/
export enum ClusterFeaturesEnum {
    SCHEMA_REGISTRY = 'SCHEMA_REGISTRY',
    KAFKA_CONNECT = 'KAFKA_CONNECT',
    KSQL_DB = 'KSQL_DB',
    TOPIC_DELETION = 'TOPIC_DELETION'
}

export function ClusterFromJSON(json: any): Cluster {
    return ClusterFromJSONTyped(json, false);
}

export function ClusterFromJSONTyped(json: any, ignoreDiscriminator: boolean): Cluster {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'defaultCluster': !exists(json, 'defaultCluster') ? undefined : json['defaultCluster'],
        'status': ServerStatusFromJSON(json['status']),
        'lastError': !exists(json, 'lastError') ? undefined : MetricsCollectionErrorFromJSON(json['lastError']),
        'brokerCount': !exists(json, 'brokerCount') ? undefined : json['brokerCount'],
        'onlinePartitionCount': !exists(json, 'onlinePartitionCount') ? undefined : json['onlinePartitionCount'],
        'topicCount': !exists(json, 'topicCount') ? undefined : json['topicCount'],
        'bytesInPerSec': !exists(json, 'bytesInPerSec') ? undefined : json['bytesInPerSec'],
        'bytesOutPerSec': !exists(json, 'bytesOutPerSec') ? undefined : json['bytesOutPerSec'],
        'readOnly': !exists(json, 'readOnly') ? undefined : json['readOnly'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'features': !exists(json, 'features') ? undefined : json['features'],
    };
}

export function ClusterToJSON(value?: Cluster | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'defaultCluster': value.defaultCluster,
        'status': ServerStatusToJSON(value.status),
        'lastError': MetricsCollectionErrorToJSON(value.lastError),
        'brokerCount': value.brokerCount,
        'onlinePartitionCount': value.onlinePartitionCount,
        'topicCount': value.topicCount,
        'bytesInPerSec': value.bytesInPerSec,
        'bytesOutPerSec': value.bytesOutPerSec,
        'readOnly': value.readOnly,
        'version': value.version,
        'features': value.features,
    };
}

