/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TaskId,
    TaskIdFromJSON,
    TaskIdFromJSONTyped,
    TaskIdToJSON,
    TaskStatus,
    TaskStatusFromJSON,
    TaskStatusFromJSONTyped,
    TaskStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface Task
 */
export interface Task {
    /**
     * 
     * @type {TaskId}
     * @memberof Task
     */
    id?: TaskId;
    /**
     * 
     * @type {TaskStatus}
     * @memberof Task
     */
    status: TaskStatus;
    /**
     * 
     * @type {{ [key: string]: any | undefined; }}
     * @memberof Task
     */
    config?: { [key: string]: any | undefined; };
}

export function TaskFromJSON(json: any): Task {
    return TaskFromJSONTyped(json, false);
}

export function TaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): Task {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : TaskIdFromJSON(json['id']),
        'status': TaskStatusFromJSON(json['status']),
        'config': !exists(json, 'config') ? undefined : json['config'],
    };
}

export function TaskToJSON(value?: Task | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': TaskIdToJSON(value.id),
        'status': TaskStatusToJSON(value.status),
        'config': value.config,
    };
}

