/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KsqlTableResponse
 */
export interface KsqlTableResponse {
    /**
     * 
     * @type {string}
     * @memberof KsqlTableResponse
     */
    header?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof KsqlTableResponse
     */
    columnNames?: Array<string>;
    /**
     * 
     * @type {Array<Array<any>>}
     * @memberof KsqlTableResponse
     */
    values?: Array<Array<any>>;
}

export function KsqlTableResponseFromJSON(json: any): KsqlTableResponse {
    return KsqlTableResponseFromJSONTyped(json, false);
}

export function KsqlTableResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KsqlTableResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'header': !exists(json, 'header') ? undefined : json['header'],
        'columnNames': !exists(json, 'columnNames') ? undefined : json['columnNames'],
        'values': !exists(json, 'values') ? undefined : json['values'],
    };
}

export function KsqlTableResponseToJSON(value?: KsqlTableResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'header': value.header,
        'columnNames': value.columnNames,
        'values': value.values,
    };
}

