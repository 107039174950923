/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MessageFilterType {
    STRING_CONTAINS = 'STRING_CONTAINS',
    GROOVY_SCRIPT = 'GROOVY_SCRIPT'
}

export function MessageFilterTypeFromJSON(json: any): MessageFilterType {
    return MessageFilterTypeFromJSONTyped(json, false);
}

export function MessageFilterTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageFilterType {
    return json as MessageFilterType;
}

export function MessageFilterTypeToJSON(value?: MessageFilterType | null): any {
    return value as any;
}

