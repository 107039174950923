/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BrokerDiskUsage,
    BrokerDiskUsageFromJSON,
    BrokerDiskUsageFromJSONTyped,
    BrokerDiskUsageToJSON,
} from './';

/**
 * 
 * @export
 * @interface ClusterStats
 */
export interface ClusterStats {
    /**
     * 
     * @type {number}
     * @memberof ClusterStats
     */
    brokerCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterStats
     */
    zooKeeperStatus?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterStats
     */
    activeControllers?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterStats
     */
    onlinePartitionCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterStats
     */
    offlinePartitionCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterStats
     */
    inSyncReplicasCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterStats
     */
    outOfSyncReplicasCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterStats
     */
    underReplicatedPartitionCount?: number;
    /**
     * 
     * @type {Array<BrokerDiskUsage>}
     * @memberof ClusterStats
     */
    diskUsage?: Array<BrokerDiskUsage>;
    /**
     * 
     * @type {string}
     * @memberof ClusterStats
     */
    version?: string;
}

export function ClusterStatsFromJSON(json: any): ClusterStats {
    return ClusterStatsFromJSONTyped(json, false);
}

export function ClusterStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClusterStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'brokerCount': !exists(json, 'brokerCount') ? undefined : json['brokerCount'],
        'zooKeeperStatus': !exists(json, 'zooKeeperStatus') ? undefined : json['zooKeeperStatus'],
        'activeControllers': !exists(json, 'activeControllers') ? undefined : json['activeControllers'],
        'onlinePartitionCount': !exists(json, 'onlinePartitionCount') ? undefined : json['onlinePartitionCount'],
        'offlinePartitionCount': !exists(json, 'offlinePartitionCount') ? undefined : json['offlinePartitionCount'],
        'inSyncReplicasCount': !exists(json, 'inSyncReplicasCount') ? undefined : json['inSyncReplicasCount'],
        'outOfSyncReplicasCount': !exists(json, 'outOfSyncReplicasCount') ? undefined : json['outOfSyncReplicasCount'],
        'underReplicatedPartitionCount': !exists(json, 'underReplicatedPartitionCount') ? undefined : json['underReplicatedPartitionCount'],
        'diskUsage': !exists(json, 'diskUsage') ? undefined : ((json['diskUsage'] as Array<any>).map(BrokerDiskUsageFromJSON)),
        'version': !exists(json, 'version') ? undefined : json['version'],
    };
}

export function ClusterStatsToJSON(value?: ClusterStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'brokerCount': value.brokerCount,
        'zooKeeperStatus': value.zooKeeperStatus,
        'activeControllers': value.activeControllers,
        'onlinePartitionCount': value.onlinePartitionCount,
        'offlinePartitionCount': value.offlinePartitionCount,
        'inSyncReplicasCount': value.inSyncReplicasCount,
        'outOfSyncReplicasCount': value.outOfSyncReplicasCount,
        'underReplicatedPartitionCount': value.underReplicatedPartitionCount,
        'diskUsage': value.diskUsage === undefined ? undefined : ((value.diskUsage as Array<any>).map(BrokerDiskUsageToJSON)),
        'version': value.version,
    };
}

