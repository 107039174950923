/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConnectorPluginConfig,
    ConnectorPluginConfigFromJSON,
    ConnectorPluginConfigFromJSONTyped,
    ConnectorPluginConfigToJSON,
} from './';

/**
 * 
 * @export
 * @interface ConnectorPluginConfigValidationResponse
 */
export interface ConnectorPluginConfigValidationResponse {
    /**
     * 
     * @type {string}
     * @memberof ConnectorPluginConfigValidationResponse
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ConnectorPluginConfigValidationResponse
     */
    errorCount?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConnectorPluginConfigValidationResponse
     */
    groups?: Array<string>;
    /**
     * 
     * @type {Array<ConnectorPluginConfig>}
     * @memberof ConnectorPluginConfigValidationResponse
     */
    configs?: Array<ConnectorPluginConfig>;
}

export function ConnectorPluginConfigValidationResponseFromJSON(json: any): ConnectorPluginConfigValidationResponse {
    return ConnectorPluginConfigValidationResponseFromJSONTyped(json, false);
}

export function ConnectorPluginConfigValidationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectorPluginConfigValidationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'errorCount': !exists(json, 'error_count') ? undefined : json['error_count'],
        'groups': !exists(json, 'groups') ? undefined : json['groups'],
        'configs': !exists(json, 'configs') ? undefined : ((json['configs'] as Array<any>).map(ConnectorPluginConfigFromJSON)),
    };
}

export function ConnectorPluginConfigValidationResponseToJSON(value?: ConnectorPluginConfigValidationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'error_count': value.errorCount,
        'groups': value.groups,
        'configs': value.configs === undefined ? undefined : ((value.configs as Array<any>).map(ConnectorPluginConfigToJSON)),
    };
}

