/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Table,
    TableFromJSON,
    TableFromJSONTyped,
    TableToJSON,
} from './';

/**
 * 
 * @export
 * @interface KsqlCommandResponse
 */
export interface KsqlCommandResponse {
    /**
     * 
     * @type {Table}
     * @memberof KsqlCommandResponse
     */
    data?: Table;
    /**
     * 
     * @type {string}
     * @memberof KsqlCommandResponse
     */
    message?: string;
}

export function KsqlCommandResponseFromJSON(json: any): KsqlCommandResponse {
    return KsqlCommandResponseFromJSONTyped(json, false);
}

export function KsqlCommandResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KsqlCommandResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : TableFromJSON(json['data']),
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function KsqlCommandResponseToJSON(value?: KsqlCommandResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': TableToJSON(value.data),
        'message': value.message,
    };
}

