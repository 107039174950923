/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConnectorAllOf,
    ConnectorAllOfFromJSON,
    ConnectorAllOfFromJSONTyped,
    ConnectorAllOfToJSON,
    ConnectorStatus,
    ConnectorStatusFromJSON,
    ConnectorStatusFromJSONTyped,
    ConnectorStatusToJSON,
    ConnectorType,
    ConnectorTypeFromJSON,
    ConnectorTypeFromJSONTyped,
    ConnectorTypeToJSON,
    NewConnector,
    NewConnectorFromJSON,
    NewConnectorFromJSONTyped,
    NewConnectorToJSON,
    TaskId,
    TaskIdFromJSON,
    TaskIdFromJSONTyped,
    TaskIdToJSON,
} from './';

/**
 * 
 * @export
 * @interface Connector
 */
export interface Connector {
    /**
     * 
     * @type {string}
     * @memberof Connector
     */
    name: string;
    /**
     * 
     * @type {{ [key: string]: any | undefined; }}
     * @memberof Connector
     */
    config: { [key: string]: any | undefined; };
    /**
     * 
     * @type {Array<TaskId>}
     * @memberof Connector
     */
    tasks?: Array<TaskId>;
    /**
     * 
     * @type {ConnectorType}
     * @memberof Connector
     */
    type: ConnectorType;
    /**
     * 
     * @type {ConnectorStatus}
     * @memberof Connector
     */
    status: ConnectorStatus;
    /**
     * 
     * @type {string}
     * @memberof Connector
     */
    connect: string;
}

export function ConnectorFromJSON(json: any): Connector {
    return ConnectorFromJSONTyped(json, false);
}

export function ConnectorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Connector {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'config': json['config'],
        'tasks': !exists(json, 'tasks') ? undefined : ((json['tasks'] as Array<any>).map(TaskIdFromJSON)),
        'type': ConnectorTypeFromJSON(json['type']),
        'status': ConnectorStatusFromJSON(json['status']),
        'connect': json['connect'],
    };
}

export function ConnectorToJSON(value?: Connector | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'config': value.config,
        'tasks': value.tasks === undefined ? undefined : ((value.tasks as Array<any>).map(TaskIdToJSON)),
        'type': ConnectorTypeToJSON(value.type),
        'status': ConnectorStatusToJSON(value.status),
        'connect': value.connect,
    };
}

