/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewConnector
 */
export interface NewConnector {
    /**
     * 
     * @type {string}
     * @memberof NewConnector
     */
    name: string;
    /**
     * 
     * @type {{ [key: string]: any | undefined; }}
     * @memberof NewConnector
     */
    config: { [key: string]: any | undefined; };
}

export function NewConnectorFromJSON(json: any): NewConnector {
    return NewConnectorFromJSONTyped(json, false);
}

export function NewConnectorFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewConnector {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'config': json['config'],
    };
}

export function NewConnectorToJSON(value?: NewConnector | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'config': value.config,
    };
}

