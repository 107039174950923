/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Metric,
    MetricFromJSON,
    MetricFromJSONTyped,
    MetricToJSON,
} from './';

/**
 * 
 * @export
 * @interface ClusterMetrics
 */
export interface ClusterMetrics {
    /**
     * 
     * @type {Array<Metric>}
     * @memberof ClusterMetrics
     */
    items?: Array<Metric>;
}

export function ClusterMetricsFromJSON(json: any): ClusterMetrics {
    return ClusterMetricsFromJSONTyped(json, false);
}

export function ClusterMetricsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClusterMetrics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(MetricFromJSON)),
    };
}

export function ClusterMetricsToJSON(value?: ClusterMetrics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(MetricToJSON)),
    };
}

