/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ConsumerGroup,
    ConsumerGroupFromJSON,
    ConsumerGroupToJSON,
    ConsumerGroupDetails,
    ConsumerGroupDetailsFromJSON,
    ConsumerGroupDetailsToJSON,
    ConsumerGroupOffsetsReset,
    ConsumerGroupOffsetsResetFromJSON,
    ConsumerGroupOffsetsResetToJSON,
    ConsumerGroupOrdering,
    ConsumerGroupOrderingFromJSON,
    ConsumerGroupOrderingToJSON,
    ConsumerGroupsPageResponse,
    ConsumerGroupsPageResponseFromJSON,
    ConsumerGroupsPageResponseToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
} from '../models';

export interface DeleteConsumerGroupRequest {
    clusterName: string;
    id: string;
}

export interface GetConsumerGroupRequest {
    clusterName: string;
    id: string;
}

export interface GetConsumerGroupsRequest {
    clusterName: string;
}

export interface GetConsumerGroupsPageRequest {
    clusterName: string;
    page?: number;
    perPage?: number;
    search?: string;
    orderBy?: ConsumerGroupOrdering;
    sortOrder?: SortOrder;
}

export interface GetTopicConsumerGroupsRequest {
    clusterName: string;
    topicName: string;
}

export interface ResetConsumerGroupOffsetsRequest {
    clusterName: string;
    id: string;
    consumerGroupOffsetsReset?: ConsumerGroupOffsetsReset;
}

/**
 * ConsumerGroupsApi - interface
 * 
 * @export
 * @interface ConsumerGroupsApiInterface
 */
export interface ConsumerGroupsApiInterface {
    /**
     * 
     * @summary Delete Consumer Group by ID
     * @param {string} clusterName 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerGroupsApiInterface
     */
    deleteConsumerGroupRaw(requestParameters: DeleteConsumerGroupRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete Consumer Group by ID
     */
    deleteConsumerGroup(requestParameters: DeleteConsumerGroupRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary get Consumer Group By Id
     * @param {string} clusterName 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerGroupsApiInterface
     */
    getConsumerGroupRaw(requestParameters: GetConsumerGroupRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ConsumerGroupDetails>>;

    /**
     * get Consumer Group By Id
     */
    getConsumerGroup(requestParameters: GetConsumerGroupRequest, initOverrides?: RequestInit): Promise<ConsumerGroupDetails>;

    /**
     * 
     * @summary get all ConsumerGroups
     * @param {string} clusterName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerGroupsApiInterface
     */
    getConsumerGroupsRaw(requestParameters: GetConsumerGroupsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ConsumerGroup>>>;

    /**
     * get all ConsumerGroups
     */
    getConsumerGroups(requestParameters: GetConsumerGroupsRequest, initOverrides?: RequestInit): Promise<Array<ConsumerGroup>>;

    /**
     * 
     * @summary Get consumer croups with paging support
     * @param {string} clusterName 
     * @param {number} [page] 
     * @param {number} [perPage] 
     * @param {string} [search] 
     * @param {ConsumerGroupOrdering} [orderBy] 
     * @param {SortOrder} [sortOrder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerGroupsApiInterface
     */
    getConsumerGroupsPageRaw(requestParameters: GetConsumerGroupsPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ConsumerGroupsPageResponse>>;

    /**
     * Get consumer croups with paging support
     */
    getConsumerGroupsPage(requestParameters: GetConsumerGroupsPageRequest, initOverrides?: RequestInit): Promise<ConsumerGroupsPageResponse>;

    /**
     * 
     * @summary get Consumer Groups By Topics
     * @param {string} clusterName 
     * @param {string} topicName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerGroupsApiInterface
     */
    getTopicConsumerGroupsRaw(requestParameters: GetTopicConsumerGroupsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ConsumerGroup>>>;

    /**
     * get Consumer Groups By Topics
     */
    getTopicConsumerGroups(requestParameters: GetTopicConsumerGroupsRequest, initOverrides?: RequestInit): Promise<Array<ConsumerGroup>>;

    /**
     * 
     * @summary resets consumer group offsets
     * @param {string} clusterName 
     * @param {string} id 
     * @param {ConsumerGroupOffsetsReset} [consumerGroupOffsetsReset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumerGroupsApiInterface
     */
    resetConsumerGroupOffsetsRaw(requestParameters: ResetConsumerGroupOffsetsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * resets consumer group offsets
     */
    resetConsumerGroupOffsets(requestParameters: ResetConsumerGroupOffsetsRequest, initOverrides?: RequestInit): Promise<void>;

}

/**
 * 
 */
export class ConsumerGroupsApi extends runtime.BaseAPI implements ConsumerGroupsApiInterface {

    /**
     * Delete Consumer Group by ID
     */
    async deleteConsumerGroupRaw(requestParameters: DeleteConsumerGroupRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling deleteConsumerGroup.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteConsumerGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/consumer-groups/{id}`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Consumer Group by ID
     */
    async deleteConsumerGroup(requestParameters: DeleteConsumerGroupRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteConsumerGroupRaw(requestParameters, initOverrides);
    }

    /**
     * get Consumer Group By Id
     */
    async getConsumerGroupRaw(requestParameters: GetConsumerGroupRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ConsumerGroupDetails>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling getConsumerGroup.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getConsumerGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/consumer-groups/{id}`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConsumerGroupDetailsFromJSON(jsonValue));
    }

    /**
     * get Consumer Group By Id
     */
    async getConsumerGroup(requestParameters: GetConsumerGroupRequest, initOverrides?: RequestInit): Promise<ConsumerGroupDetails> {
        const response = await this.getConsumerGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get all ConsumerGroups
     */
    async getConsumerGroupsRaw(requestParameters: GetConsumerGroupsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ConsumerGroup>>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling getConsumerGroups.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/consumer-groups`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ConsumerGroupFromJSON));
    }

    /**
     * get all ConsumerGroups
     */
    async getConsumerGroups(requestParameters: GetConsumerGroupsRequest, initOverrides?: RequestInit): Promise<Array<ConsumerGroup>> {
        const response = await this.getConsumerGroupsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get consumer croups with paging support
     */
    async getConsumerGroupsPageRaw(requestParameters: GetConsumerGroupsPageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ConsumerGroupsPageResponse>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling getConsumerGroupsPage.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.sortOrder !== undefined) {
            queryParameters['sortOrder'] = requestParameters.sortOrder;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/consumer-groups/paged`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConsumerGroupsPageResponseFromJSON(jsonValue));
    }

    /**
     * Get consumer croups with paging support
     */
    async getConsumerGroupsPage(requestParameters: GetConsumerGroupsPageRequest, initOverrides?: RequestInit): Promise<ConsumerGroupsPageResponse> {
        const response = await this.getConsumerGroupsPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get Consumer Groups By Topics
     */
    async getTopicConsumerGroupsRaw(requestParameters: GetTopicConsumerGroupsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ConsumerGroup>>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling getTopicConsumerGroups.');
        }

        if (requestParameters.topicName === null || requestParameters.topicName === undefined) {
            throw new runtime.RequiredError('topicName','Required parameter requestParameters.topicName was null or undefined when calling getTopicConsumerGroups.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/topics/{topicName}/consumer-groups`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"topicName"}}`, encodeURIComponent(String(requestParameters.topicName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ConsumerGroupFromJSON));
    }

    /**
     * get Consumer Groups By Topics
     */
    async getTopicConsumerGroups(requestParameters: GetTopicConsumerGroupsRequest, initOverrides?: RequestInit): Promise<Array<ConsumerGroup>> {
        const response = await this.getTopicConsumerGroupsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * resets consumer group offsets
     */
    async resetConsumerGroupOffsetsRaw(requestParameters: ResetConsumerGroupOffsetsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling resetConsumerGroupOffsets.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling resetConsumerGroupOffsets.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/clusters/{clusterName}/consumer-groups/{id}/offsets`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConsumerGroupOffsetsResetToJSON(requestParameters.consumerGroupOffsetsReset),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * resets consumer group offsets
     */
    async resetConsumerGroupOffsets(requestParameters: ResetConsumerGroupOffsetsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.resetConsumerGroupOffsetsRaw(requestParameters, initOverrides);
    }

}
