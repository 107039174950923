/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateTopicMessage
 */
export interface CreateTopicMessage {
    /**
     * 
     * @type {number}
     * @memberof CreateTopicMessage
     */
    partition: number;
    /**
     * 
     * @type {string}
     * @memberof CreateTopicMessage
     */
    key?: string | null;
    /**
     * 
     * @type {{ [key: string]: string | undefined; }}
     * @memberof CreateTopicMessage
     */
    headers?: { [key: string]: string | undefined; };
    /**
     * 
     * @type {string}
     * @memberof CreateTopicMessage
     */
    content?: string | null;
}

export function CreateTopicMessageFromJSON(json: any): CreateTopicMessage {
    return CreateTopicMessageFromJSONTyped(json, false);
}

export function CreateTopicMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTopicMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'partition': json['partition'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'headers': !exists(json, 'headers') ? undefined : json['headers'],
        'content': !exists(json, 'content') ? undefined : json['content'],
    };
}

export function CreateTopicMessageToJSON(value?: CreateTopicMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'partition': value.partition,
        'key': value.key,
        'headers': value.headers,
        'content': value.content,
    };
}

