/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ConsumerGroupState {
    UNKNOWN = 'UNKNOWN',
    PREPARING_REBALANCE = 'PREPARING_REBALANCE',
    COMPLETING_REBALANCE = 'COMPLETING_REBALANCE',
    STABLE = 'STABLE',
    DEAD = 'DEAD',
    EMPTY = 'EMPTY'
}

export function ConsumerGroupStateFromJSON(json: any): ConsumerGroupState {
    return ConsumerGroupStateFromJSONTyped(json, false);
}

export function ConsumerGroupStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsumerGroupState {
    return json as ConsumerGroupState;
}

export function ConsumerGroupStateToJSON(value?: ConsumerGroupState | null): any {
    return value as any;
}

