/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConfigSource,
    ConfigSourceFromJSON,
    ConfigSourceFromJSONTyped,
    ConfigSourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface ConfigSynonym
 */
export interface ConfigSynonym {
    /**
     * 
     * @type {string}
     * @memberof ConfigSynonym
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigSynonym
     */
    value?: string;
    /**
     * 
     * @type {ConfigSource}
     * @memberof ConfigSynonym
     */
    source?: ConfigSource;
}

export function ConfigSynonymFromJSON(json: any): ConfigSynonym {
    return ConfigSynonymFromJSONTyped(json, false);
}

export function ConfigSynonymFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigSynonym {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'source': !exists(json, 'source') ? undefined : ConfigSourceFromJSON(json['source']),
    };
}

export function ConfigSynonymToJSON(value?: ConfigSynonym | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'value': value.value,
        'source': ConfigSourceToJSON(value.source),
    };
}

