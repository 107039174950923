/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReplicationFactorChange
 */
export interface ReplicationFactorChange {
    /**
     * 
     * @type {number}
     * @memberof ReplicationFactorChange
     */
    totalReplicationFactor: number;
}

export function ReplicationFactorChangeFromJSON(json: any): ReplicationFactorChange {
    return ReplicationFactorChangeFromJSONTyped(json, false);
}

export function ReplicationFactorChangeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReplicationFactorChange {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalReplicationFactor': json['totalReplicationFactor'],
    };
}

export function ReplicationFactorChangeToJSON(value?: ReplicationFactorChange | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalReplicationFactor': value.totalReplicationFactor,
    };
}

