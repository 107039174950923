/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Metric,
    MetricFromJSON,
    MetricFromJSONTyped,
    MetricToJSON,
} from './';

/**
 * 
 * @export
 * @interface BrokerMetrics
 */
export interface BrokerMetrics {
    /**
     * 
     * @type {number}
     * @memberof BrokerMetrics
     */
    segmentSize?: number;
    /**
     * 
     * @type {number}
     * @memberof BrokerMetrics
     */
    segmentCount?: number;
    /**
     * 
     * @type {Array<Metric>}
     * @memberof BrokerMetrics
     */
    metrics?: Array<Metric>;
}

export function BrokerMetricsFromJSON(json: any): BrokerMetrics {
    return BrokerMetricsFromJSONTyped(json, false);
}

export function BrokerMetricsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrokerMetrics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'segmentSize': !exists(json, 'segmentSize') ? undefined : json['segmentSize'],
        'segmentCount': !exists(json, 'segmentCount') ? undefined : json['segmentCount'],
        'metrics': !exists(json, 'metrics') ? undefined : ((json['metrics'] as Array<any>).map(MetricFromJSON)),
    };
}

export function BrokerMetricsToJSON(value?: BrokerMetrics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'segmentSize': value.segmentSize,
        'segmentCount': value.segmentCount,
        'metrics': value.metrics === undefined ? undefined : ((value.metrics as Array<any>).map(MetricToJSON)),
    };
}

