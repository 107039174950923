/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BrokerTopicPartitionLogdirAllOf,
    BrokerTopicPartitionLogdirAllOfFromJSON,
    BrokerTopicPartitionLogdirAllOfFromJSONTyped,
    BrokerTopicPartitionLogdirAllOfToJSON,
    TopicPartitionLogdir,
    TopicPartitionLogdirFromJSON,
    TopicPartitionLogdirFromJSONTyped,
    TopicPartitionLogdirToJSON,
} from './';

/**
 * 
 * @export
 * @interface BrokerTopicPartitionLogdir
 */
export interface BrokerTopicPartitionLogdir {
    /**
     * 
     * @type {number}
     * @memberof BrokerTopicPartitionLogdir
     */
    partition?: number;
    /**
     * 
     * @type {number}
     * @memberof BrokerTopicPartitionLogdir
     */
    size?: number;
    /**
     * 
     * @type {number}
     * @memberof BrokerTopicPartitionLogdir
     */
    offsetLag?: number;
    /**
     * 
     * @type {number}
     * @memberof BrokerTopicPartitionLogdir
     */
    broker?: number;
}

export function BrokerTopicPartitionLogdirFromJSON(json: any): BrokerTopicPartitionLogdir {
    return BrokerTopicPartitionLogdirFromJSONTyped(json, false);
}

export function BrokerTopicPartitionLogdirFromJSONTyped(json: any, ignoreDiscriminator: boolean): BrokerTopicPartitionLogdir {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'partition': !exists(json, 'partition') ? undefined : json['partition'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'offsetLag': !exists(json, 'offsetLag') ? undefined : json['offsetLag'],
        'broker': !exists(json, 'broker') ? undefined : json['broker'],
    };
}

export function BrokerTopicPartitionLogdirToJSON(value?: BrokerTopicPartitionLogdir | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'partition': value.partition,
        'size': value.size,
        'offsetLag': value.offsetLag,
        'broker': value.broker,
    };
}

