/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateTopicMessage,
    CreateTopicMessageFromJSON,
    CreateTopicMessageToJSON,
    MessageFilterType,
    MessageFilterTypeFromJSON,
    MessageFilterTypeToJSON,
    SeekDirection,
    SeekDirectionFromJSON,
    SeekDirectionToJSON,
    SeekType,
    SeekTypeFromJSON,
    SeekTypeToJSON,
    TopicMessageEvent,
    TopicMessageEventFromJSON,
    TopicMessageEventToJSON,
    TopicMessageSchema,
    TopicMessageSchemaFromJSON,
    TopicMessageSchemaToJSON,
} from '../models';

export interface DeleteTopicMessagesRequest {
    clusterName: string;
    topicName: string;
    partitions?: Array<number>;
}

export interface GetTopicMessagesRequest {
    clusterName: string;
    topicName: string;
    seekType?: SeekType;
    seekTo?: Array<string>;
    limit?: number;
    q?: string;
    filterQueryType?: MessageFilterType;
    seekDirection?: SeekDirection;
}

export interface GetTopicSchemaRequest {
    clusterName: string;
    topicName: string;
}

export interface SendTopicMessagesRequest {
    clusterName: string;
    topicName: string;
    createTopicMessage?: CreateTopicMessage;
}

/**
 * MessagesApi - interface
 * 
 * @export
 * @interface MessagesApiInterface
 */
export interface MessagesApiInterface {
    /**
     * 
     * @summary deleteTopicMessages
     * @param {string} clusterName 
     * @param {string} topicName 
     * @param {Array<number>} [partitions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApiInterface
     */
    deleteTopicMessagesRaw(requestParameters: DeleteTopicMessagesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * deleteTopicMessages
     */
    deleteTopicMessages(requestParameters: DeleteTopicMessagesRequest, initOverrides?: RequestInit): Promise<void>;

    /**
     * 
     * @summary getTopicMessages
     * @param {string} clusterName 
     * @param {string} topicName 
     * @param {SeekType} [seekType] 
     * @param {Array<string>} [seekTo] The format is [partition]::[offset] for specifying offsets or [partition]::[timestamp in millis] for specifying timestamps
     * @param {number} [limit] 
     * @param {string} [q] 
     * @param {MessageFilterType} [filterQueryType] 
     * @param {SeekDirection} [seekDirection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApiInterface
     */
    getTopicMessagesRaw(requestParameters: GetTopicMessagesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<TopicMessageEvent>>>;

    /**
     * getTopicMessages
     */
    getTopicMessages(requestParameters: GetTopicMessagesRequest, initOverrides?: RequestInit): Promise<Array<TopicMessageEvent>>;

    /**
     * 
     * @summary getTopicSchema
     * @param {string} clusterName 
     * @param {string} topicName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApiInterface
     */
    getTopicSchemaRaw(requestParameters: GetTopicSchemaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TopicMessageSchema>>;

    /**
     * getTopicSchema
     */
    getTopicSchema(requestParameters: GetTopicSchemaRequest, initOverrides?: RequestInit): Promise<TopicMessageSchema>;

    /**
     * 
     * @summary sendTopicMessages
     * @param {string} clusterName 
     * @param {string} topicName 
     * @param {CreateTopicMessage} [createTopicMessage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApiInterface
     */
    sendTopicMessagesRaw(requestParameters: SendTopicMessagesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>>;

    /**
     * sendTopicMessages
     */
    sendTopicMessages(requestParameters: SendTopicMessagesRequest, initOverrides?: RequestInit): Promise<void>;

}

/**
 * 
 */
export class MessagesApi extends runtime.BaseAPI implements MessagesApiInterface {

    /**
     * deleteTopicMessages
     */
    async deleteTopicMessagesRaw(requestParameters: DeleteTopicMessagesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling deleteTopicMessages.');
        }

        if (requestParameters.topicName === null || requestParameters.topicName === undefined) {
            throw new runtime.RequiredError('topicName','Required parameter requestParameters.topicName was null or undefined when calling deleteTopicMessages.');
        }

        const queryParameters: any = {};

        if (requestParameters.partitions) {
            queryParameters['partitions'] = requestParameters.partitions;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/topics/{topicName}/messages`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"topicName"}}`, encodeURIComponent(String(requestParameters.topicName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * deleteTopicMessages
     */
    async deleteTopicMessages(requestParameters: DeleteTopicMessagesRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteTopicMessagesRaw(requestParameters, initOverrides);
    }

    /**
     * getTopicMessages
     */
    async getTopicMessagesRaw(requestParameters: GetTopicMessagesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<TopicMessageEvent>>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling getTopicMessages.');
        }

        if (requestParameters.topicName === null || requestParameters.topicName === undefined) {
            throw new runtime.RequiredError('topicName','Required parameter requestParameters.topicName was null or undefined when calling getTopicMessages.');
        }

        const queryParameters: any = {};

        if (requestParameters.seekType !== undefined) {
            queryParameters['seekType'] = requestParameters.seekType;
        }

        if (requestParameters.seekTo) {
            queryParameters['seekTo'] = requestParameters.seekTo;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.filterQueryType !== undefined) {
            queryParameters['filterQueryType'] = requestParameters.filterQueryType;
        }

        if (requestParameters.seekDirection !== undefined) {
            queryParameters['seekDirection'] = requestParameters.seekDirection;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/topics/{topicName}/messages`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"topicName"}}`, encodeURIComponent(String(requestParameters.topicName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TopicMessageEventFromJSON));
    }

    /**
     * getTopicMessages
     */
    async getTopicMessages(requestParameters: GetTopicMessagesRequest, initOverrides?: RequestInit): Promise<Array<TopicMessageEvent>> {
        const response = await this.getTopicMessagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getTopicSchema
     */
    async getTopicSchemaRaw(requestParameters: GetTopicSchemaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TopicMessageSchema>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling getTopicSchema.');
        }

        if (requestParameters.topicName === null || requestParameters.topicName === undefined) {
            throw new runtime.RequiredError('topicName','Required parameter requestParameters.topicName was null or undefined when calling getTopicSchema.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/clusters/{clusterName}/topics/{topicName}/messages/schema`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"topicName"}}`, encodeURIComponent(String(requestParameters.topicName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TopicMessageSchemaFromJSON(jsonValue));
    }

    /**
     * getTopicSchema
     */
    async getTopicSchema(requestParameters: GetTopicSchemaRequest, initOverrides?: RequestInit): Promise<TopicMessageSchema> {
        const response = await this.getTopicSchemaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * sendTopicMessages
     */
    async sendTopicMessagesRaw(requestParameters: SendTopicMessagesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.clusterName === null || requestParameters.clusterName === undefined) {
            throw new runtime.RequiredError('clusterName','Required parameter requestParameters.clusterName was null or undefined when calling sendTopicMessages.');
        }

        if (requestParameters.topicName === null || requestParameters.topicName === undefined) {
            throw new runtime.RequiredError('topicName','Required parameter requestParameters.topicName was null or undefined when calling sendTopicMessages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/clusters/{clusterName}/topics/{topicName}/messages`.replace(`{${"clusterName"}}`, encodeURIComponent(String(requestParameters.clusterName))).replace(`{${"topicName"}}`, encodeURIComponent(String(requestParameters.topicName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTopicMessageToJSON(requestParameters.createTopicMessage),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * sendTopicMessages
     */
    async sendTopicMessages(requestParameters: SendTopicMessagesRequest, initOverrides?: RequestInit): Promise<void> {
        await this.sendTopicMessagesRaw(requestParameters, initOverrides);
    }

}
